export const getters = {
  getCartId(state) {
    return state.localStorage.cart_unique_id;
  },
  getWishListId(state) {
    return state.localStorage.wishlist_unique_id;
  },
  getCompareListId(state) {
    return state.localStorage.comparlist_unique_id;
  },
};

export const mutations = {
  SET_LOCAL_VAR(state, payload) {
    state.localStorage[payload.var] = payload.val;
  },
};

export const actions = {
  resetCart({ commit }) {
    commit("cart/RESET_CART");
    commit("SET_LOCAL_VAR", {
      var: "cart_unique_id",
      val: null,
    });
  },
  resetWishList({ commit }) {
    commit("wishlist/RESET_WISHLIST");
    commit("SET_LOCAL_VAR", {
      var: "wishlist_unique_id",
      val: null,
    });
    localStorage.setItem("conf-m8ht77-wishlist", JSON.stringify([]));
  },
  resetCompareList({ commit }) {
    commit("comparison/RESET_COMPARELIST");
    commit("SET_LOCAL_VAR", {
      var: "comparlist_unique_id",
      val: null,
    });
    localStorage.setItem("conf-m8ht77-compare", JSON.stringify([]));
  },
  async getCart({ commit, state, getters }, route) {
    try {
      let cartId = getters.getCartId;

      let getCartByCartID = false; // TODO refactor it
      const cartUniqueIdRoutes = ["checkout", "cart"];
      if (
        route &&
        cartUniqueIdRoutes.some((path) =>
          route.path.split("/").includes(path)
        ) &&
        route.params.id &&
        route.params.id !== cartId
      ) {
        cartId = route.params.id;
        getCartByCartID = true;
      }

      const userInfo = getters["auth/getUserInfo"];
      const { data } = await this.$api.cart.getCart({
        cartId,
        userInfo,
        getCartByCartID,
      });
      commit("SET_LOCAL_VAR", {
        var: "cart_unique_id",
        val: data.data.unique_id,
      });
      commit("cart/SET_CART_INFO", data.data);
    } catch (err) {
      console.error("err");
      return Promise.reject(err);
    }
  },
  async getWishList({ commit, getters }) {
    try {
      const wishListId = getters.getWishListId;
      const userInfo = getters["auth/getUserInfo"];
      const { data } = await this.$api.wishlist.getWishList({
        wishListId,
        userInfo,
      });
      commit("SET_LOCAL_VAR", {
        var: "wishlist_unique_id",
        val: data.data.unique_id,
      });
      commit("wishlist/SET_WISHLIST", data.data.baggage);
      const localStorageData =
        data?.data?.baggage?.map((el) => ({
          engraving: Array.isArray(el.engraving) ? {} : el.engraving,
          sku: el.sku,
        })) || [];
      localStorage.setItem(
        "conf-m8ht77-wishlist",
        JSON.stringify(localStorageData)
      );
    } catch (err) {
      console.error("err");
      console.log("err - ", err);
    }
  },
  async getCompareList({ commit, getters }) {
    try {
      const compareListId = getters.getCompareListId;
      const userInfo = getters["auth/getUserInfo"];
      // console.log("wishListId - ", wishListId);
      const { data } = await this.$api.comparison.getCompareList({
        compareListId,
        userInfo,
      });
      commit("SET_LOCAL_VAR", {
        var: "comparlist_unique_id",
        val: data.data.unique_id,
      });
      commit("comparison/SET_COMPARE_LIST", data.data.baggage);
      const localStorageData =
        data?.data?.baggage?.map((el) => ({
          engraving: el.engraving,
          sku: el.sku,
        })) || [];
      localStorage.setItem(
        "conf-m8ht77-compare",
        JSON.stringify(localStorageData)
      );
    } catch (err) {
      console.error("err");
      console.log("err - ", err);
    }
  },
  nuxtServerInit({ dispatch, commit }, { $cookies }) {
    // await dispatch("general/getSiteSettings"); moved to plugins
    // const userInfo = $cookies.get("user_info");
    // if (userInfo) {
    //   dispatch("auth/serUserInfo", userInfo);
    // }
  },
  nuxtClientInit({ dispatch, getters, state }, route) {
    // try {
    //   await dispatch("getCart");
    //   const cartId = getters.getCartId;
    //   await dispatch("orders/getShippingMethods", { cart_unique_id: cartId });
    // } catch (err) {
    //   console.log("err - ", err);
    // }
    async function getCartPromise() {
      try {
        await dispatch("getCart", route);
        const cartId = getters.getCartId;
        await dispatch("orders/getShippingMethods", { cart_unique_id: cartId });
        return Promise.resolve();
      } catch (err) {
        console.error("err - ", err);
        return Promise.reject(err);
      }
    }
    const promises = [
      dispatch("getWishList"),
      // dispatch("getCompareList"),
      getCartPromise(),
    ];
    Promise.allSettled(promises);
    if (!state.general.collectionList?.length) {
      setTimeout(() => {
        dispatch("general/fetchCollectionList");
      }, 4000);
    }

    // await dispatch("getCart");
    // await dispatch("getWishList");
    // await dispatch("getCompareList");
    // const cartId = getters.getCartId;
    // await dispatch("orders/getShippingMethods", { cart_unique_id: cartId });
  },
};
