export default function ({ store, redirect, localePath, route }) {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  if (!isAuthenticated) {
    return redirect(
      localePath({
        path: "/login",
        query: {
          redirect: route.fullPath,
        },
      })
    );
  }
}
