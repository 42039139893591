export default (axios) => ({
  /**
   * Get cart
   *
   * @link https://devapi.omara.sk/apidoc/#api-Cart-GetApiV1Carts
   * @param payload
   * @returns {*}
   */
  getCart(payload) {
    const params = {};
    if (
      (payload?.cartId && payload.getCartByCartID) ||
      (payload?.cartId && !payload?.userInfo)
    ) {
      params.cart_unique_id = payload.cartId;
    }

    return axios.get("/carts", { params });
  },
  /**
   * Create cart item
   *
   * @link https://devapi.omara.sk/apidoc/#api-Cart-PostApiV1CartsItems
   * @param payload
   * @returns {*}
   */
  setCartItem(payload) {
    return axios.post("/carts/items", payload);
  },

  /**
   * Update cart item
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Cart-PatchApiV1CartsItemsId
   * @param id
   * @param payload
   * @returns {*}
   */
  updateCartItem(id, payload) {
    return axios.patch(`/carts/items/${id}`, payload);
  },

  /**
   * Delete cart item
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Cart-DeleteApiV1CartsItemsId
   * @param id
   * @param params
   * @returns {*}
   */
  deleteCartItem(id, params) {
    return axios.delete(`/carts/items/${id}`, { params });
  },

  /**
   * Bind gift card or discount to cart
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Cart-PostApiV1CartsBindGiftCard
   * @param params
   * @returns {*}
   */
  bindDiscount(params) {
    return axios.post(`/carts/bind-gift-card`, params);
  },

  /**
   * Bind gift card or discount to cart
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Cart-PostApiV1CartsUnbindGiftCard
   * @param params
   * @returns {*}
   */
  unbindDiscount(params) {
    return axios.post(`/carts/unbind-gift-card`, params);
  },

  /**
   * Storing checkout data
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Cart-PostApiV1CartsCheckout
   * @param params
   * @returns {*}
   */
  checkout(params) {
    return axios.post(`/carts/checkout`, params);
  },

  /**
   * Create Stripe Payment Session
   *
   * @link https://devapi.omara.sk/apidoc/#api-Cart-PostApiV1CartsCheckoutStripeSession
   * @param params
   * @returns {*}
   */
  createStripePaymentSession(payload) {
    return axios.post(`/carts/checkout/stripe/session`, payload);
  },

  /**
   * Get Checkout Stripe Session
   *
   * @param params
   * @returns {*}
   */
  getCheckoutStripeSession(params) {
    return axios.get("/carts/checkout/stripe/session", { params });
  },
  /**
   * Start checkout event
   *
   * @link
   * @returns {*}
   * @param cart_unique_id
   */
  sendStartCheckoutEvent(cart_unique_id) {
    return axios.patch(`/carts/${cart_unique_id}/start-checkout`);
  },

  /**
   * Update cart
   *
   * @link
   * @returns {*}
   * @param cart_unique_id
   * @param data
   */
  updateCart(cart_unique_id, data) {
    return axios.patch(`/carts/${cart_unique_id}`, data);
  },
});
