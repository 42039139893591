export default (axios) => ({
  /**
   * Get pages
   *
   * @link https://devapi.omara.sk/apidoc/#api-Page-GetApiV1PagesPageid
   * @param data
   * @returns {*}
   */
  getPage(slug) {
    return axios.get(`/pages/${slug}`);
  },
  /**
   * Get block
   *
   * @link https://devapi.omara.sk/apidoc/#api-Blockembedded_code-GetApiV1BlocksBlockname
   * @param data
   * @returns {*}
   */
  getBlok(slug) {
    return axios.get(`/blocks/${slug}`);
  },
});

