import { parseJson } from "~/utils";

const POPUPS_LOCALSTORAGE_KEY = "popups";

export function getLocalStoragePopups() {
  return parseJson(localStorage.getItem(POPUPS_LOCALSTORAGE_KEY)) || [];
}

export function setLocalStoragePopups(data) {
  localStorage.setItem(POPUPS_LOCALSTORAGE_KEY, JSON.stringify(data));
}
