import axios from "axios";
export default async ({ store }) => {
  const INTERVAL_PERIOD = 1000 * 60 * 3; // 3 minutes

  // fetch data from '/upload/c/cache_version.json'
  async function getData() {
    try {
      // https://devapi.diamondclub.com/cv
      const API_DOMAIN = process.env.API_URL.replace("/api/v1", "");
      const res = await axios.get(API_DOMAIN + "/cv");
      return res.data || {};
    } catch (error) {
      console.error(error);
    }
  }

  if (process.server) {
    const data = await getData();
    store.commit("general/SET_CACHE_VERSIONS", data);
  }

  if (process.client) {
    setInterval(async () => {
      const data = await getData();
      store.commit("general/SET_CACHE_VERSIONS", data);
    }, INTERVAL_PERIOD);
  }
};
