export default ({ store }, inject) => {
  const alert = (state) => {
    store.dispatch("systemPopup/setDialogState", {
      ...state,
      open: true,
      type: "alert",
    });
  };
  const confirm = (state) => {
    store.dispatch("systemPopup/setDialogState", {
      ...state,
      open: true,
      type: "confirm",
    });
  };
  inject("alert", alert);
  inject("confirm", confirm);
};
