import { render, staticRenderFns } from "./UiFilter.vue?vue&type=template&id=0f788e7c&scoped=true&"
import script from "./UiFilter.vue?vue&type=script&lang=js&"
export * from "./UiFilter.vue?vue&type=script&lang=js&"
import style0 from "./UiFilter.vue?vue&type=style&index=0&id=0f788e7c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f788e7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiRadio: require('/opt/atlassian/pipelines/agent/build/components/UI/UiRadio.vue').default,UiCheckbox: require('/opt/atlassian/pipelines/agent/build/components/UI/UiCheckbox.vue').default,UiAccordionItem: require('/opt/atlassian/pipelines/agent/build/components/UI/UiAccordionItem.vue').default,UiAccordion: require('/opt/atlassian/pipelines/agent/build/components/UI/UiAccordion.vue').default})
