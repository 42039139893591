export default (axios) => ({
  /**
   * Subscribe
   *
   * @link https://devapi.omara.sk/apidoc/#api-Public-PostApiV1Subscribe
   * @param data
   * @returns {*}
   */
  subscribe(data) {
    return axios.post("/subscribe", data);
  },
  /**
   * Create product question
   *
   * @link https://devapi.omara.sk/apidoc/#api-FrontQuestions-PostApiV1Questions
   * @param data
   * @returns {*}
   */
   createProductQuestion(data) {
    return axios.post(`/questions`, data);
  },
});