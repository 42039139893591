export default (axios) => ({
  /**
   * Lоgin
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-User-PostApiV1Authorizations
   * @param data
   * @returns {*}
   */
  signIn(data) {
    return axios.post("/authorizations", data);
  },

  /**
   * Register
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-User-PostApiV1AuthorizationsSignUp
   * @param data
   * @returns {*}
   */
  signUp(data) {
    return axios.post("/authorizations/sign-up", data);
  },

  /**
   * Logout
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-User-DeleteApiV1Authorizations
   * @returns {*}
   */
  signOut() {
    return axios.delete("/authorizations");
  },
});
