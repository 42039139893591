export const state = () => ({
  compareList: [],
});

export const getters = {
  getCompareList(state) {
    return state.compareList;
  },
};

export const mutations = {
  SET_COMPARE_LIST(state, payload) {
    if(!payload) {
      return;
    }
    const mapedPayload = payload.map(el => ({
      ...el,
      engraving: Array.isArray(el.engraving) ? {} : el.engraving,
    }));
    state.compareList = mapedPayload;

    const localStorageData =
      mapedPayload.map((el) => ({
        engraving: el.engraving,
        sku: el.sku,
      })) || [];
    localStorage.setItem(
      "conf-m8ht77-compare",
      JSON.stringify(localStorageData)
    );
  },
  RESET_COMPARELIST(state) {
    state.compareList = [];
  },
};

export const actions = {
  setCompareList({ commit }, payload) {
    console.log("payload - ", payload);
    commit("SET_COMPARE_LIST", payload);
  },
};