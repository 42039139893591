import { mapGetters } from "vuex";
import { addCurrentTranslation } from "~/utils";
import { BUILDER_EVENTS } from "~/builder/builderConfig";

export default {
  computed: {
    ...mapGetters({
      getCurrentLangId: "general/getCurrentLangId",
      getDefaultLangId: "general/getDefaultLangId",
      isEditMode: "general/getIsEditMode",
    }),
  },
  mounted() {
    window.addEventListener("message", this.handleMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    async handleChangePage(data) {
      const slug = data.slug === "home" ? "" : data.slug;
      const url = this.$router.resolve(
        this.localePath({
          path: `/${slug}`,
        })
      );
      const currentUrl = this.$router.resolve(this.$route);
      if (url.href !== currentUrl.href) {
        await this.$router.replace(url.href);
      }
    },
    handleMessage(e) {
      if (!this.isEditMode) return;
      const data = JSON.parse(e.data);

      const dataWithTranslation = addCurrentTranslation(
        data.data,
        this.getCurrentLangId(this.$i18n.locale),
        this.getDefaultLangId
      );

      switch (data.event) {
        case BUILDER_EVENTS.CHANGE_PAGE:
          this.handleChangePage(dataWithTranslation);
          break;
      }
    },
  },
};
