
import { generateId } from "~/utils";

export default {
  name: "UiRadio",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    modelValue: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      internalId: null,
    };
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
  mounted() {
    this.internalId = generateId();
  },
};
