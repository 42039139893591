export default (axios) => ({
  /**
   * Recovery password
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-User-PatchApiV1UsersRecoverPassword
   * @param data
   * @returns {*}
   */
  recoveryPasswordRequest(data) {
    return axios.patch("/users/recover-password", data);
  },

  /**
   * Change password by recovery token
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-User-PatchApiV1UsersChangePassword
   * @param data
   * @returns {*}
   */
  changePasswordByRecoveryToken(data) {
    return axios.patch("/users/change-password", data);
  },

  /**
   * Verify Email
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-User-PostApiV1UsersVerifyEmail
   * @param data
   * @returns {*}
   */
  verifyEmail(data) {
    return axios.post("/users/verify-email", data);
  },
  /**
   * Update session user
   *
   * @link https://devapi.omara.sk/apidoc/#api-User-PutApiV1Users
   * @param data
   * @returns {*}
   */
  updateUser(data) {
    return axios.put("/users", data);
  },
  /**
   * List of the session user subscriptions
   *
   * @link https://devapi.omara.sk/apidoc/#api-User-GetApiV1UsersSubscriptions
   * @returns {*}
   */
  getUserSubscriptions() {
    return axios.get("/users/subscriptions");
  },
  /**
   * List of an user shipping addresses
   *
   * @link https://devapi.omara.sk/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns {*}
   */
  getUserShippingAddress() {
    return axios.get("/users/addresses/shipping");
  },
  /**
   * Create a new shipping address for a session user
   *
   * @link https://devapi.omara.sk/apidoc/#api-User-PostApiV1UsersAddressesShipping
   * @param data
   * @returns {*}
   */
  setUserShippingAddress(data) {
    return axios.post("/users/addresses/shipping", data);
  },
  /**
   * Update a shipping address of a session user
   *
   * @link https://devapi.omara.sk/apidoc/#api-User-PutApiV1UsersAddressesShippingId
   * @param data
   * @returns {*}
   */
  updateUserShippingAddress(data, id) {
    return axios.put(`/users/addresses/shipping/${id}`, data);
  },
  /**
   * Delete an user shipping address
   *
   * @link https://devapi.omara.sk/apidoc/#api-User-DeleteApiV1UsersAddressesShippingId
   * @param data
   * @returns {*}
   */
  deleteUserShippingAddress(id) {
    return axios.delete(`/users/addresses/shipping/${id}`);
  },

  /**
   * Get User
   *
   * @link https://devapi.omara.sk/apidoc/
   * @returns {*}
   */
  getUserData() {
    return axios.get("/users");
  },
});
