export default (axios) => ({
  /**
   * Send front event to statistic collector
   *
   * @link
   * @returns {*}
   * @param data
   * @param urlSettings
   */
  sendEventToSC(data, urlSettings) {
    return axios.post(`${urlSettings.base_url}/stats`, data, {
      params: {
        api_key: urlSettings.api_key,
      },
    });
  },

  /**
   * Send front event to backend
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Events
   * @returns {*}
   * @param data
   */
  sendEventToBack(data) {
    return axios.post("/events/frontend-event", data);
  },
});
