export const state = () => ({
  userInfo: null,
});

export const getters = {
  isAuthenticated(state) {
    return !!state.userInfo;
  },
  getToken(state) {
    return state.userInfo?.access_token?.token;
  },
  getUserInfo(state) {
    return state.userInfo;
  },
};

export const mutations = {
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },
};

export const actions = {
  serUserInfo({ commit }, payload) {
    commit("SET_USER_INFO", payload);
    this.$cookies.set("user_info", payload);
  },
  removeToken({ commit }) {
    commit("SET_USER_INFO", null);
    this.$cookies.remove("user_info");
  },
  async fetchUserInfo({ commit, dispatch }) {
    try {
      const res = await this.$api.user.getUserData();
      const userInfo = this.$cookies.get("user_info");

      const payload = {
        ...userInfo,
        ...res.data.data,
        access_token: userInfo.access_token,
      };
      dispatch("serUserInfo", payload);
    } catch (error) {
      console.error("fetchUserInfo error", error);
    }
  },
};
