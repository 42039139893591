
export default {
  provide() {
    return { Accordion: this.Accordion };
  },
  props: {
    openInxByDefault: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      Accordion: {
        count: 0,
        active: null,
      },
    };
  },
  created() {
    if(this.openInxByDefault || this.openInxByDefault === 0) {
      this.Accordion.active = this.openInxByDefault;
    }
  },
};
