export default (axios) => ({
  /**
   * Get product
   *
   * @link https://devapi.omara.sk/apidoc/#api-Products-GetApiV1ProductsProductid
   * @param id
   * @returns {*}
   */
  getProduct(id) {
    return axios.get(`/products/${id}`);
  },
  /**
   * Get catalog
   *
   * @link https://devapi.omara.sk/apidoc/#api-Products-GetApiV1Catalog
   * @param params
   * @returns {*}
   */
   getCatalog(params, slug) {
    return axios.get(`/system-catalog${slug? "/"+ slug: ""}`, { params });
  },
  /**
   * Get collections list
   *
   * @link https://devapi.omara.sk/apidoc/#api-Collections-GetApiV1Collections
   * @returns {*}
   */
   getCollectionsList() {
    return axios.get("/collections");
  },
  /**
   * Get collection by slug
   *
   * @link https://devapi.omara.sk/apidoc/#api-Collections-GetApiV1CollectionsCollectionslug
   * @param slug
   * @returns {*}
   */
   getCollectionBySlug(slug) {
    return axios.get(`/collections/${slug}`);
  },
  /**
   * Get entities
   *
   * @link https://devapi.omara.sk/apidoc/#api-SystemEntity-GetApiV1SystemEntitiesEntitytype
   * @param entityType
   * @param params
   * @returns {*}
   */
  getEntities(entityType, params) {
    return axios.get(`/system-entities/${entityType}`, { params });
  },
  /**
   * Get configuration
   *
   * @link https://devapi.omara.sk/public/apidoc/#api-Products-GetApiV1ProductConfigurationsConfigurationid
   * @returns {*}
   * @param configurationId
   */
  getConfiguration(configurationId) {
    return axios.get(`/product-configurations/${configurationId}`);
  },
});