import qs from "qs";
import { addCurrentTranslation } from "~/utils/index.js";
import { CACHE_API_ENDPOINTS } from "~/config";

function getCacheVersionParam(config, store) {
  const slug = config.url.split("/")?.[1];

  const isMethodGet =
    config?.method?.toLowerCase() === "get" || !config?.method;
  if (isMethodGet && slug && Object.keys(CACHE_API_ENDPOINTS).includes(slug)) {
    const param = CACHE_API_ENDPOINTS?.[slug];

    return {
      cv: store.getters["general/getCacheVersion"](param) || undefined,
    };
  }
  return {};
}

export default function ({ $axios, store, app, redirect }) {
  $axios.onRequest((config) => {
    if (store.getters["auth/isAuthenticated"]) {
      config.headers.common.Authorization = `Bearer ${store.getters["auth/getToken"]}`;
    }
    const defaultLangCode = (store.getters["general/getLanguages"] || []).find(
      (lang) => lang.is_default
    )?.slug;

    let lang = app.i18n.locale;

    if (defaultLangCode && defaultLangCode !== app.i18n.locale) {
      lang = `${app.i18n.locale},${defaultLangCode}`;
    }

    return {
      ...config,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "brackets" }),
      headers: {
        ...config.headers,
        // 'Accept-Language': 'en',
        "Accept-Language": app.i18n.locale,
        lang,
        // 'X_USER_UUID': app.store.getters.getUserUUID
      },
      params: {
        ...config.params,
        ...getCacheVersionParam(config, store),
        lang,
      },
    };
  });

  $axios.onResponse((response) => {
    // console.log('response', response);
    const currentLangId = (store.getters["general/getLanguages"] || []).find(
      (lang) => lang.slug === app.i18n.locale
    )?.id;
    const defaultLangId = (store.getters["general/getLanguages"] || []).find(
      (lang) => lang.is_default
    )?.id;

    const langId = currentLangId || defaultLangId;
    if (response && response.data) {
      const res = {
        ...response,
        data: addCurrentTranslation(response.data, langId, defaultLangId),
      };
      if (res.data && res.data.error_code) {
        switch (res.data.error_code) {
          case 404: {
            // redirect('/404');
            break;
          }
          case 403: {
            // redirect('/404');
            break;
          }
          case 503: {
            // make a page for this
            redirect("/404");
            break;
          }
          case 401: {
            store.dispatch("auth/removeToken");
            redirect("/");
            break;
          }
        }
      }
      return res;
    }
    // console.log('response', res);
  });

  $axios.onError(({ response }) => {
    // console.log('error res', response);
    if (response && response.data) {
      if (response.status === 404) {
        // redirect('/404');
      }
      if (response.status === 403) {
        // redirect('/403');
      }
      if (response.status === 503) {
        // redirect('/maintenance');
      }
      if (response.status === 401) {
        store.dispatch("auth/removeToken");
        redirect("/");
      }
    }
    // return Promise.reject(response)
  });
}
