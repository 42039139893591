import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7301acba = () => interopDefault(import('../pages/500.vue' /* webpackChunkName: "pages/500" */))
const _91c94102 = () => interopDefault(import('../pages/articles.vue' /* webpackChunkName: "pages/articles" */))
const _78dc0ac2 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _411eafe9 = () => interopDefault(import('../pages/articles/_section/index.vue' /* webpackChunkName: "pages/articles/_section/index" */))
const _3f680ea1 = () => interopDefault(import('../pages/articles/_section/_slug.vue' /* webpackChunkName: "pages/articles/_section/_slug" */))
const _30c598e5 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _3eb0a50a = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _73230e8b = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _5fff4486 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _781a73ee = () => interopDefault(import('../pages/journey.vue' /* webpackChunkName: "pages/journey" */))
const _0b88c44c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _24b5cacc = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _a64d3586 = () => interopDefault(import('../pages/checkout/payout.vue' /* webpackChunkName: "pages/checkout/payout" */))
const _b63cf07e = () => interopDefault(import('../pages/profile/order-tracking/index.vue' /* webpackChunkName: "pages/profile/order-tracking/index" */))
const _6991ae66 = () => interopDefault(import('../pages/profile/orders.vue' /* webpackChunkName: "pages/profile/orders" */))
const _371fadaa = () => interopDefault(import('../pages/profile/shipping-addresses.vue' /* webpackChunkName: "pages/profile/shipping-addresses" */))
const _70b110a7 = () => interopDefault(import('../pages/checkout/success/_id.vue' /* webpackChunkName: "pages/checkout/success/_id" */))
const _3c3eda2c = () => interopDefault(import('../pages/profile/order-tracking/_id/index.vue' /* webpackChunkName: "pages/profile/order-tracking/_id/index" */))
const _d382dee6 = () => interopDefault(import('../pages/cart/_id.vue' /* webpackChunkName: "pages/cart/_id" */))
const _3cfa03c2 = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _6ead909a = () => interopDefault(import('../pages/checkout/_id.vue' /* webpackChunkName: "pages/checkout/_id" */))
const _0d65845e = () => interopDefault(import('../pages/collection/_slug/index.vue' /* webpackChunkName: "pages/collection/_slug/index" */))
const _3e87319b = () => interopDefault(import('../pages/product/_slug/index.vue' /* webpackChunkName: "pages/product/_slug/index" */))
const _096dd676 = () => interopDefault(import('../pages/recover-password/_token/index.vue' /* webpackChunkName: "pages/recover-password/_token/index" */))
const _8fb621fe = () => interopDefault(import('../pages/verify/_token/index.vue' /* webpackChunkName: "pages/verify/_token/index" */))
const _2df0c58d = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/500",
    component: _7301acba,
    name: "500___sk"
  }, {
    path: "/articles",
    component: _91c94102,
    children: [{
      path: "",
      component: _78dc0ac2,
      name: "articles___sk"
    }, {
      path: ":section",
      component: _411eafe9,
      name: "articles-section___sk"
    }, {
      path: ":section/:slug",
      component: _3f680ea1,
      name: "articles-section-slug___sk"
    }]
  }, {
    path: "/cart",
    component: _30c598e5,
    name: "cart___sk"
  }, {
    path: "/catalog",
    component: _3eb0a50a,
    name: "catalog___sk"
  }, {
    path: "/checkout",
    component: _73230e8b,
    name: "checkout___sk"
  }, {
    path: "/collections",
    component: _5fff4486,
    name: "collections___sk"
  }, {
    path: "/en",
    component: _f334fd00,
    name: "index___en"
  }, {
    path: "/journey",
    component: _781a73ee,
    name: "journey___sk"
  }, {
    path: "/login",
    component: _0b88c44c,
    name: "login___sk"
  }, {
    path: "/profile",
    component: _24b5cacc,
    name: "profile___sk"
  }, {
    path: "/checkout/payout",
    component: _a64d3586,
    name: "checkout-payout___sk"
  }, {
    path: "/en/500",
    component: _7301acba,
    name: "500___en"
  }, {
    path: "/en/articles",
    component: _91c94102,
    children: [{
      path: "",
      component: _78dc0ac2,
      name: "articles___en"
    }, {
      path: ":section",
      component: _411eafe9,
      name: "articles-section___en"
    }, {
      path: ":section/:slug",
      component: _3f680ea1,
      name: "articles-section-slug___en"
    }]
  }, {
    path: "/en/cart",
    component: _30c598e5,
    name: "cart___en"
  }, {
    path: "/en/catalog",
    component: _3eb0a50a,
    name: "catalog___en"
  }, {
    path: "/en/checkout",
    component: _73230e8b,
    name: "checkout___en"
  }, {
    path: "/en/collections",
    component: _5fff4486,
    name: "collections___en"
  }, {
    path: "/en/journey",
    component: _781a73ee,
    name: "journey___en"
  }, {
    path: "/en/login",
    component: _0b88c44c,
    name: "login___en"
  }, {
    path: "/en/profile",
    component: _24b5cacc,
    name: "profile___en"
  }, {
    path: "/profile/order-tracking",
    component: _b63cf07e,
    name: "profile-order-tracking___sk"
  }, {
    path: "/profile/orders",
    component: _6991ae66,
    name: "profile-orders___sk"
  }, {
    path: "/profile/shipping-addresses",
    component: _371fadaa,
    name: "profile-shipping-addresses___sk"
  }, {
    path: "/en/checkout/payout",
    component: _a64d3586,
    name: "checkout-payout___en"
  }, {
    path: "/en/profile/order-tracking",
    component: _b63cf07e,
    name: "profile-order-tracking___en"
  }, {
    path: "/en/profile/orders",
    component: _6991ae66,
    name: "profile-orders___en"
  }, {
    path: "/en/profile/shipping-addresses",
    component: _371fadaa,
    name: "profile-shipping-addresses___en"
  }, {
    path: "/en/checkout/success/:id?",
    component: _70b110a7,
    name: "checkout-success-id___en"
  }, {
    path: "/en/profile/order-tracking/:id",
    component: _3c3eda2c,
    name: "profile-order-tracking-id___en"
  }, {
    path: "/checkout/success/:id?",
    component: _70b110a7,
    name: "checkout-success-id___sk"
  }, {
    path: "/en/cart/:id",
    component: _d382dee6,
    name: "cart-id___en"
  }, {
    path: "/en/catalog/:slug",
    component: _3cfa03c2,
    name: "catalog-slug___en"
  }, {
    path: "/en/checkout/:id",
    component: _6ead909a,
    name: "checkout-id___en"
  }, {
    path: "/en/collection/:slug",
    component: _0d65845e,
    name: "collection-slug___en"
  }, {
    path: "/en/product/:slug",
    component: _3e87319b,
    name: "product-slug___en"
  }, {
    path: "/en/recover-password/:token",
    component: _096dd676,
    name: "recover-password-token___en"
  }, {
    path: "/en/verify/:token",
    component: _8fb621fe,
    name: "verify-token___en"
  }, {
    path: "/profile/order-tracking/:id",
    component: _3c3eda2c,
    name: "profile-order-tracking-id___sk"
  }, {
    path: "/cart/:id",
    component: _d382dee6,
    name: "cart-id___sk"
  }, {
    path: "/catalog/:slug",
    component: _3cfa03c2,
    name: "catalog-slug___sk"
  }, {
    path: "/checkout/:id",
    component: _6ead909a,
    name: "checkout-id___sk"
  }, {
    path: "/collection/:slug",
    component: _0d65845e,
    name: "collection-slug___sk"
  }, {
    path: "/product/:slug",
    component: _3e87319b,
    name: "product-slug___sk"
  }, {
    path: "/recover-password/:token",
    component: _096dd676,
    name: "recover-password-token___sk"
  }, {
    path: "/verify/:token",
    component: _8fb621fe,
    name: "verify-token___sk"
  }, {
    path: "/en/*",
    component: _2df0c58d,
    name: "all___en"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index___sk"
  }, {
    path: "/*",
    component: _2df0c58d,
    name: "all___sk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
