
import { mapGetters } from "vuex";
export default {
  name: "UiLazyImg",
  props: {
    src: {
      type: [String],
      required: false,
      default: "",
    },
    srcMobile: {
      type: [String, undefined],
      required: false,
      default: "",
    },
    objectFit: {
      type: String,
      default: "cover",
    },
    imgStyle: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "Omara",
    },
    useLazyLoad: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      isEditMode: "general/getIsEditMode",
    }),
    imageClasses() {
      if (this.objectFit === "cover") {
        return "cover";
      }
      return "contain";
    },
  },
};
