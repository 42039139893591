import { popupsConfig } from "~/config";
import { getLocalStoragePopups, isToday, setLocalStoragePopups, getRandomArrayItem } from "~/utils";

function checkFrequency(popup, foundedPopup, pageLoaded) {
  switch (popup.frequency) {
    case popupsConfig.popupFrequency.ONCE: {
      if (!foundedPopup.last_opened_at) {
        return popup;
      }
      return null;
    }
    case popupsConfig.popupFrequency.EACH_PAGE_REFRESH: {
      if (!pageLoaded) {
        return popup;
      }
      return null;
    }
    case popupsConfig.popupFrequency.EACH_PAGE_VIEW: {
      return popup;
    }
    case popupsConfig.popupFrequency.PER_DAY: {
      if (
        !foundedPopup.last_opened_at ||
        !isToday(new Date(foundedPopup.last_opened_at))
      ) {
        return popup;
      }
      return null;
    }
    default: {
      return null;
    }
  }
}

function findPriorityPopup(popups) {
  return (
    popups.reduce((acc, popup) => {
      if (!acc.length) {
        acc.push(popup);
      } else if (popup.priority > acc[0].priority) {
        acc = [popup];
      }
      return acc;
    }, [])?.[0] || null
  );
}

export default ({ app, store, $api }) => {
  async function getPopups(route) {
    const res = await $api.general.getPopups({ url: route });

    return findPriorityPopup(res.data.data);
  }

  function getDiscountCampaignPopup(route) {
    let discountCampaignPopups = store.getters["general/getDiscountCampaignsData"]?.popups || [];
    let discountCampaignPopup = null;
    if (discountCampaignPopups.length) {
      const discountCampaignTopBannerId = store.getters["general/getDiscountCampaignsData"]?.topBanner?.discount_campaign_id;
      discountCampaignPopups = discountCampaignPopups.filter(item => !item.pages?.length || item.pages.some(page => page.page_link === route));
      discountCampaignPopup = discountCampaignPopups.find(item => item.discount_campaign_id === discountCampaignTopBannerId) || getRandomArrayItem(discountCampaignPopups);
    }
    return discountCampaignPopup;
  }

  let pageLoaded = false;
  let timeOut = -1;
  // app.router.afterEach - will wait for the script to finish executing in other plugins with await, dont need any hooks for Discount Campaigns loading
  app.router.afterEach((to, from) => {
    clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
      const currentPopup = await getPopups(to.path) || getDiscountCampaignPopup(to.path);
      if (!currentPopup) return;
      const storePopups = getLocalStoragePopups();

      const foundedPopup = storePopups.find(
        (popup) => popup.id === currentPopup.id
      );

      const notEqualFrequency =
        foundedPopup && foundedPopup.frequency !== currentPopup.frequency;

      if (!foundedPopup) {
        const popups = [
          ...storePopups,
          {
            id: currentPopup.id,
            frequency: currentPopup.frequency,
            last_opened_at: null,
          },
        ];
        setLocalStoragePopups(popups);
        await store.dispatch("general/setSitePopup", currentPopup);
      } else if (notEqualFrequency) {
        const popups = storePopups.map((item) => {
          return item.id === currentPopup.id
            ? {
                id: currentPopup.id,
                frequency: currentPopup.frequency,
                last_opened_at: null,
              }
            : item;
        });
        setLocalStoragePopups(popups);
        await store.dispatch("general/setSitePopup", currentPopup);
      } else {
        const popup = checkFrequency(currentPopup, foundedPopup, pageLoaded);
        await store.dispatch("general/setSitePopup", popup);
      }
      pageLoaded = true;
    }, 4000);
  });
};
