import $axios from "axios";

const excludeUrls = ["/main"];

async function getAppRoutes() {
  const result = await $axios.get(
    `${process.env.API_URL}/sitemap?secret=cXwV4Kfz94YjzaaqsO0l`
  );
  return result.data.data?.sitemap?.filter?.(
    ({ url }) => !excludeUrls.includes(url)
  );
}

export const sitemapConfig = {
  async routes() {
    return await getAppRoutes();
  },
  path: "/sitemap.xml",
  gzip: true,
  // i18n: {
  //   locales: lang.locales.map(el => el.code),
  // },
  hostname: process.env.DOMAIN,
  exclude: [
    "/profile",
    "/main",
    "/profile/**",
    "/500",
    "/cart",
    "/checkout",
    "/login",
  ],
};
