export const BUILDER_STOREFRONT_EVENTS = {
  VIEW_PAGE: "viewPage",
  HOVER_BLOCK: "hoverBlock",
  SELECT_BLOCK: "selectBlock",
};

export const BUILDER_EVENTS = {
  CHANGE_PAGE: "changePage",
  SELECT_BLOCK: "selectBlock",
  UPDATE_BLOCK_CONTENT: "updateBlockContent",
  REMOVE_BLOCK: "removeBlock",
  SORT_BLOCKS: "sortBlocks",
};
