
export default {
  name: "UiPagination",
  props: {
    pageCount: {
      type: Number,
      required: true,
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
};
