import { getRedirectUrl } from "~/utils";

export default function ({ store, $api, switchLocalePath, i18n, redirect, $cookies, query, route, env, ...args }) {
  const browserLang = i18n.getBrowserLocale();

  const userLocale = route.query?.userLocale || $cookies.get("userLocale");

  let existedSystemLanguage = null;

  if(userLocale) {
    existedSystemLanguage = store.getters["general/getLanguages"].find(lang => lang.slug === userLocale);
  }
  if(!existedSystemLanguage && browserLang) {
    existedSystemLanguage = store.getters["general/getLanguages"].find(lang => lang.slug === browserLang);
  }

  if(existedSystemLanguage && !existedSystemLanguage.domain_name?.includes(env.DOMAIN) && existedSystemLanguage.language_redirect) {
    if(existedSystemLanguage.domain_name) {
      const path = route.fullPath;
      const queryParams = { userLocale: existedSystemLanguage?.slug };
      const availableLocales = i18n.localeCodes;
      redirect(getRedirectUrl(path, availableLocales, queryParams, existedSystemLanguage.domain_name));
    }
  }
}
