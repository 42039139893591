import { throttle } from "lodash";

export const thresholds = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1410,
};
export default ({ store }) => {
  function getClientWidth() {
    return Math.max(document.documentElement?.clientWidth, window.innerWidth);
  }

  function getClientHeight() {
    return Math.max(document.documentElement?.clientHeight, window.innerHeight);
  }

  const defaultOptions = {
    delay: 150,
    thresholds,
  };

  const state = {};
  let width = getClientWidth();
  let height = getClientHeight();

  function createDisplay(options = defaultOptions) {
    const { thresholds, delay } = Object.assign(defaultOptions, options);

    const onResize = throttle(() => {
      height = getClientHeight();
      width = getClientWidth();

      setState(thresholds);
      store.commit("general/SET_APP_BREAKPOINTS", state);
    }, delay);

    onResize();
    window.addEventListener("resize", onResize, { passive: true });

    return state;
  }

  function setState(thresholds) {
    const xs = width < thresholds.sm;
    const sm = width < thresholds.md && !xs;
    const md = width < thresholds.lg && !(sm || xs);
    const lg = width < thresholds.xl && !(md || sm || xs);
    const xl = width >= thresholds.xl;
    const name = xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : "xl";
    state.height = height;
    state.width = width;
    state.xs = xs;
    state.sm = sm;
    state.md = md;
    state.lg = lg;
    state.xl = xl;
    state.name = name;
    state.smAndUp = !xs;
    state.mdAndUp = !(xs || sm);
    state.lgAndUp = !(xs || sm || md);
    state.xlAndUp = !(xs || sm || md || lg);
    state.smAndDown = !(md || lg || xl);
    state.mdAndDown = !(lg || xl);
    state.lgAndDown = !xl && !lg;
  }
  createDisplay();
};
