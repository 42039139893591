import { mapGetters } from "vuex";

export default {
  head() {
    return {
      link: [...this.filesListLinks, ...this.filesListMeta],
    };
  },
  computed: {
    ...mapGetters({
      settings: "general/getSettings",
    }),
    filePath() {
      return `${this.settings?.cdn_domain}/favicon/`;
    },
    filesListLinks() {
      return [
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "36x36",
        //   href: "android-icon-36x36.png",
        // },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "48x48",
        //   href: "android-icon-48x48.png",
        // },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "72x72",
        //   href: "android-icon-72x72.png",
        // },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "96x96",
        //   href: "android-icon-96x96.png",
        // },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "144x144",
        //   href: "android-icon-144x144.png",
        // },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "192x192",
        //   href: "android-icon-192x192.png",
        // },
        {
          rel: "apple-touch-icon",
          sizes: "57x57",
          href: this.filePath + "apple-icon-57x57.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "60x60",
          href: this.filePath + "apple-icon-60x60.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "72x72",
          href: this.filePath + "apple-icon-72x72.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "76x76",
          href: this.filePath + "apple-icon-76x76.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "114x114",
          href: this.filePath + "apple-icon-114x114.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "120x120",
          href: this.filePath + "apple-icon-120x120.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "144x144",
          href: this.filePath + "apple-icon-144x144.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "152x152",
          href: this.filePath + "apple-icon-152x152.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: this.filePath + "apple-icon-180x180.png",
        },
        {
          rel: "apple-touch-icon-precomposed",
          href: this.filePath + "apple-icon-precomposed.png",
        },
        {
          rel: "apple-touch-icon",
          href: this.filePath + "apple-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: this.filePath + "favicon-16x16.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: this.filePath + "favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "96x96",
          href: this.filePath + "favicon-96x96.png",
        },
        {
          rel: "icon",
          type: "image/x-icon",
          href: this.filePath + "favicon.ico",
        },
        {
          rel: "manifest",
          href: this.filePath + "manifest.json",
        },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "70x70",
        //   href: "ms-icon-70x70.png",
        // },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "144x144",
        //   href: "ms-icon-144x144.png",
        // },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "150x150",
        //   href: "ms-icon-150x150.png",
        // },
        // {
        //   rel: "icon",
        //   type: "image/png",
        //   sizes: "310x310",
        //   href: "ms-icon-310x310.png",
        // },
      ];
    },
    filesListMeta() {
      return [
        {
          name: "msapplication-config",
          content: this.filePath + "browserconfig.xml",
        },
      ];
    },
  },
};
