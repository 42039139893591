const defaultDialogState = {
  type: "alert", // alert | confirm
  open: false,
  title: "",
  text: "",
  successHandler() {},
  cancelHandler() {},
};

export const state = () => ({
  dialogState: defaultDialogState,
});

export const getters = {
  getDialogState(state) {
    return state.dialogState;
  },
};

export const mutations = {
  SET_DIALOG_STATE(state, payload) {
    state.dialogState = Object.assign({}, state.dialogState, payload);
  },
};

export const actions = {
  setDialogState({ commit }, payload = defaultDialogState) {
    commit("SET_DIALOG_STATE", payload);
  },
};
