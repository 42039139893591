/* eslint-disable camelcase */
import Vue from "vue";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  localize,
} from "vee-validate";
import {
  digits,
  required,
  email,
  min,
  max,
  regex,
  required_if,
  confirmed,
} from "vee-validate/dist/rules";

localize({
  en: {
    messages: {
      required: (_field_) => `${_field_} is required`,
      min: (_, { _field_, length }) =>
        `${_field_} must be at least ${length} characters`,
      max: (_, { _field_, length }) =>
        `${_field_} must have no more than ${length} characters`,
      passwordChar: (_field_) =>
        `${_field_} should contain Lower case, Upper case, a number and a special character`,
      confirmed: (_, { _field_ }) => {
        return `${_field_} field confirmation does not match`;
      },
      phone_number: (_field_) => `${_field_} is wrong`,
      email: (_field_) => `${_field_} is incorrect`,
      validate_zip: (_field_) => `${_field_} is incorrect`,
    },
  },
  sk: {
    messages: {
      required: (_field_) => `Pole ${_field_} je povinné`,
      min: (_, { _field_, length }) =>
        `${_field_} musí mať aspoň ${length} znakov`,
      max: (_, { _field_, length }) =>
        `${_field_} nesmie mať viac ako ${length} znakov`,
      passwordChar: (_field_) =>
        `${_field_} by malo obsahovať malé písmená, veľké písmená, číslo a špeciálny znak`,
      confirmed: (_, { _field_ }) => {
        return `${_field_} pole potvrdenia sa nezhoduje`;
      },
      phone_number: (_field_) => `${_field_} je nesprávne`,
      email: (_field_) => `${_field_} je nesprávna`,
      validate_zip: (_field_) => `${_field_} nie je spravné`,
    },
  },
  cs: {
    messages: {
      required: (_field_) => `Pole ${_field_} je povinné`,
      min: (_, { _field_, length }) =>
        `${_field_} musí mít alespoň ${length} znaků`,
      max: (_, { _field_, length }) =>
        `${_field_} nesmí mít více než ${length} znaků`,
      passwordChar: (_field_) =>
        `${_field_} by mělo obsahovat malá písmena, velká písmena, číslo a speciální znak`,
      confirmed: (_, { _field_ }) => {
        return `${_field_} pole potvrzení se neshoduje`;
      },
      phone_number: (_field_) => `${_field_} je chybné`,
      email: (_field_) => `${_field_} je chybná`,
      validate_zip: (_field_) => `${_field_} je chybné`,
    },
  },
  pl: {
    messages: {
      required: (_field_) => `Pole ${_field_} jest obowiązkowe`,
      min: (_, { _field_, length }) =>
        `${_field_} musi mieć co najmniej ${length} znaków`,
      max: (_, { _field_, length }) =>
        `${_field_} nie może mieć więcej niż ${length} znaków`,
      passwordChar: (_field_) =>
        `${_field_} powinno zawierać małe i duże litery, cyfrę oraz znak specjalny`,
      confirmed: (_, { _field_ }) => {
        return `${_field_} pole potwierdzenia nie pasuje`;
      },
      phone_number: (_field_) => `${_field_} jest nieprawidłowy`,
      email: (_field_) => `${_field_} jest nieprawidłowy`,
      validate_zip: (_field_) => `${_field_} jest nieprawidłowy`,
    },
  },
});

// extend("required", { ...required, message: "The {_field_} is required" });
extend("required", { ...required });
extend("email", email);
extend("min", {
  ...min,
});
extend("max", max);
extend("confirmed", confirmed);
extend("regex", regex);
extend("required_if", required_if);

extend("digits", digits);

extend("passwordChar", {
  validate: (value) => {
    const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[,.#?!@$%^&*-]).*$/;
    const passwordRegex = new RegExp(reg);
    return passwordRegex.test(String(value));
  },
});

extend("phone_number", {
  validate: (value) => {
    const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    const phoneRegex = new RegExp(reg);
    return phoneRegex.test(String(value));
  },
});

extend("validate_zip", {
  validate: (value) => {
    // const noSpaceValue = value.replaceAll(" ", "");
    // const reg = /^\d{5}(?:[-\s]\d{4})?$/;
    // const zipRegex = new RegExp(reg);
    // return zipRegex.test(String(noSpaceValue));
    return true; // remove zip code validation
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

export default function ({ app }) {
  localize(app.i18n.locale);
}
