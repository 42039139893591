export default (axios) => ({
  /**
   * Add to wishlist
   *
   * @link https://devapi.omara.sk/apidoc/#api-Wishlist-PostApiV1Wishlists
   * @param data
   * @returns {*}
   */
  addItem(data) {
    return axios.post("/wishlists", data);
  },
  /**
   * Get or create wishlist
   *
   * @link https://devapi.omara.sk/apidoc/#api-Wishlist-GetApiV1Wishlists
   * @param data
   * @returns {*}
   */
   getWishList(payload) {
    const params = {};
    if (payload?.wishListId && !payload?.userInfo) {
      params.unique_id = payload.wishListId;
    }
    return axios.get("/wishlists", { params });
  },
});
