export const state = () => ({
  shippingMethods: [],
});

export const getters = {
  getShippingMethods(state) {
    return state.shippingMethods;
  },
};

export const mutations = {
  SET_SHIPPING_METHODS(state, payload) {
    state.shippingMethods = payload;
  },
};

export const actions = {
  async getShippingMethods({ commit }, payload) {
    try {
      const { data } = await this.$api.orders.getShippingMethods(payload);
      commit("SET_SHIPPING_METHODS", data.data.rates);
      return Promise.resolve(data.data.rates);
    } catch (e) {
      console.log("err - ", e);
      return Promise.reject(e);
    }
  },
};
