import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getOperateStatisticsIntegration:
        "general/getOperateStatisticsIntegration",
    }),
    canSendOperateStatistic() {
      return (
        this.getOperateStatisticsIntegration?.events_enabled &&
        this.getOperateStatisticsIntegration?.base_url &&
        this.getOperateStatisticsIntegration?.api_key
      );
    },
  },
  methods: {
    async useOperateStatisticsEvent(payload) {
      if (!this.canSendOperateStatistic) {
        return;
      }
      try {
        await this.$api.events.sendEventToSC(payload, {
          base_url: this.getOperateStatisticsIntegration.base_url,
          api_key: this.getOperateStatisticsIntegration.api_key,
        });
      } catch (err) {
        console.error("Error sending OperateStatisticsEvent:", err);
      }
    },
  },
};
