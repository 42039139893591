import { mapGetters } from "vuex";
import { EVENT_GENDERS, EVENTS_ID } from "@/config";
import useZendeskAndKlaviyoEvents from "~/mixins/useZendeskAndKlaviyoEvents";
import useOperateStatisticsEvent from "~/mixins/useOperateStatisticsEvent";

export default {
  mixins: [useZendeskAndKlaviyoEvents, useOperateStatisticsEvent],
  data() {
    return {
      statisticObserver: null,
    };
  },
  computed: {
    ...mapGetters({
      cartId: "getCartId",
      userInfo: "auth/getUserInfo",
      getSettings: "general/getSettings",
    }),
    language() {
      return this.$i18n.locale;
    },
    uid() {
      return this.$cookies.get("uid");
    },
  },
  methods: {
    createUrl() {
      const route = this.$route || this.currentApp?.context?.route;
      const frontDomain = this.getSettings?.front_domain;
      const cleanFrontDomain =
        frontDomain && frontDomain?.trim()?.endsWith("/")
          ? frontDomain.slice(0, -1)
          : frontDomain;
      const domain = cleanFrontDomain || this.$config?.DOMAIN || "";
      const queryParams = window?.location?.search || "";
      return `${domain}${route?.path || ""}${queryParams}`;
    },
    initIntersectionObserver(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target;
          const opEvent = target.getAttribute("data-op-event-id") || "";
          const opWeight = target.getAttribute("data-op-weight") || "";
          const opTag = target.getAttribute("data-op-tag") || "";
          const opScrollDepth =
            target.getAttribute("data-op-scroll-depth") || "";

          this.scrollDepthEvent({
            op_event_id: opEvent,
            op_weight: opWeight,
            op_tag: opTag,
            op_scroll_depth: opScrollDepth,
          });

          this.statisticObserver?.unobserve(target);
        }
      });
    },

    observerOnMounted() {
      if (process.client && "IntersectionObserver" in window) {
        this.statisticObserver = new IntersectionObserver(
          this.initIntersectionObserver
        );
        const elements = document.querySelectorAll("[data-op-stats]");
        elements.forEach((element) => {
          this.statisticObserver?.observe(element);
        });
      }
    },

    observerOnBeforeUnmount() {
      if (this.statisticObserver) {
        this.statisticObserver.disconnect();
      }
    },

    payloadBuilder(eventPayload = {}) {
      const payload = {
        uid: this.uid || "",
        user_id: this.userInfo?.id || "",
        user_email: this.userInfo?.email || "",
        user_phone: this.userInfo?.phone || "",
        url: this.createUrl() || "",
        event_id: "",
        weight: "",
        sex: EVENT_GENDERS?.[this.userInfo?.gender] || "",
        age: null,
        tag: "",
        product_id: "",
        configuration_id: "",
        search_query: "",
        scroll_depth: "",
        collection_id: "",
        product_sku: "",
        product_price: "",
        order_id: "",
        cart_id: this.cartId || "",
        checkout_id: this.cartId || "",
        message: "",
        referrer: "",
        language: this.language || "",
      };
      Object.assign(payload, eventPayload);
      return payload;
    },

    visitEvent() {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.VISIT,
      });
      this.useOperateStatisticsEvent(payload);
      this.useZendeskAndKlaviyoEvents(payload);
    },

    addToCartEvent({
      product_id,
      configuration_id,
      collection_id,
      product_sku,
      product_price,
    }) {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.ADD_TO_CART,
        product_id: product_id || "",
        configuration_id: configuration_id || "",
        collection_id: collection_id || "",
        product_sku: product_sku || "",
        product_price: product_price || "",
      });
      this.useOperateStatisticsEvent(payload);
    },

    removeFromCartEvent({
      product_id,
      configuration_id,
      collection_id,
      product_sku,
      product_price,
    }) {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.REMOVE_FROM_CART,
        product_id: product_id || "",
        configuration_id: configuration_id || "",
        collection_id: collection_id || "",
        product_sku: product_sku || "",
        product_price: product_price || "",
      });
      this.useOperateStatisticsEvent(payload);
    },

    beginCheckoutEvent() {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.BEGIN_CHECKOUT,
      });
      this.useOperateStatisticsEvent(payload);
    },

    addPaymentInfoEvent() {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.ADD_PAYMENT_INFO,
      });
      this.useOperateStatisticsEvent(payload);
      this.useZendeskAndKlaviyoEvents(payload);
    },

    addShippingInfoEvent({ shipping_email, shipping_phone_number }) {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.ADD_SHIPPING_INFO,
        user_email: shipping_email || this.userInfo?.email || "",
        user_phone: shipping_phone_number || this.userInfo?.phone || "",
      });
      this.useOperateStatisticsEvent(payload);
      this.useZendeskAndKlaviyoEvents(payload);
    },

    checkoutErrorEvent(errorMessage) {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.CHECKOUT_ERROR,
        message: errorMessage || "",
      });
      this.useOperateStatisticsEvent(payload);
    },

    purchaseEvent({ purchase_user_email, purchase_user_phone, order_id }) {
      const payload = this.payloadBuilder({
        user_email: purchase_user_email || "",
        user_phone: purchase_user_phone || "",
        event_id: EVENTS_ID.PURCHASE,
        order_id: order_id || "",
      });
      this.useOperateStatisticsEvent(payload);
    },

    searchEvent(search_query) {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.SEARCH,
        search_query: search_query || "",
      });
      this.useOperateStatisticsEvent(payload);
    },

    addToFavoritesEvent({
      product_id,
      configuration_id,
      product_sku,
      product_price,
      collection_id,
    }) {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.ADD_TO_FAVORITES,
        product_id: product_id || "",
        configuration_id: configuration_id || "",
        collection_id: collection_id || "",
        product_sku: product_sku || "",
        product_price: product_price || "",
      });
      this.useOperateStatisticsEvent(payload);
    },

    removeFromFavoritesEvent({
      product_id,
      configuration_id,
      product_sku,
      product_price,
      collection_id,
    }) {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.REMOVE_FROM_FAVORITES,
        product_id: product_id || "",
        configuration_id: configuration_id || "",
        collection_id: collection_id || "",
        product_sku: product_sku || "",
        product_price: product_price || "",
      });
      this.useOperateStatisticsEvent(payload);
    },

    remainEvent() {
      const payload = this.payloadBuilder({
        event_id: EVENTS_ID.REMAIN,
      });
      this.useOperateStatisticsEvent(payload);
      this.useZendeskAndKlaviyoEvents(payload);
    },

    scrollDepthEvent({ op_event_id, op_weight, op_tag, op_scroll_depth }) {
      const payload = this.payloadBuilder({
        event_id: op_event_id,
        weight: op_weight,
        tag: op_tag,
        scroll_depth: op_scroll_depth,
      });
      this.useOperateStatisticsEvent(payload);
      this.useZendeskAndKlaviyoEvents(payload);
    },
  },
};
