import Vue from "vue";
import useEvents from "~/mixins/useEvents";

export default ({ app, ...args }) => {
  const EventsComponent = Vue.extend({
    mixins: [useEvents],
    computed: {
      currentApp() {
        return app;
      },
    },
    ...args,
  });

  const eventsInstance = new EventsComponent();
  const INTERVAL = 30000;

  setInterval(() => {
    eventsInstance.remainEvent();
  }, INTERVAL);
};
