import Vue from "vue";
import useEvents from "~/mixins/useEvents";

export default async ({ app, store, route, ...args }) => {
  await store.dispatch("nuxtClientInit", route);

  const EventsComponent = Vue.extend({
    mixins: [useEvents],
    computed: {
      currentApp() {
        return app;
      },
    },
    store,
    ...args,
  });
  const eventsInstance = new EventsComponent();
  eventsInstance.visitEvent();
};
