export default (axios) => ({
  /**
   * Adds item for comparison
   *
   * @link https://devapi.omara.sk/apidoc/#api-Comparison-PostApiV1Comparisons
   * @param data
   * @returns {*}
   */
  addItem(data) {
    return axios.post("/comparisons", data);
  },
  /**
   * Get comparisons
   *
   * @link https://devapi.omara.sk/apidoc/#api-Comparison-GetApiV1Comparisons
   * @param data
   * @returns {*}
   */
   getCompareList(payload) {
    const params = {};
    if (payload?.compareListId && !payload?.userInfo) {
      params.unique_id = payload.compareListId;
    }
    return axios.get("/comparisons", { params });
  },
});