
import ProductCard from "~/components/common/ProductCard";
import UiPagination from "~/components/UI/UiPagination";
import UiSpinner from "@/components/UI/UiSpinner.vue";
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents";
import PrevArrowSvg from "~/components/svg/PrevArrowSvg.vue";
import jsonLdMixin from "~/mixins/jsonLdMixin";
import { getImageByPosition } from "~/utils";

export default {
  name: "CatalogBlock",
  components: {
    PrevArrowSvg,
    UiPagination,
    ProductCard,
    UiSpinner,
  },
  mixins: [googleAnalyticsEvents, jsonLdMixin],
  props: {
    currentCollection: {
      type: Object,
      default: () => {},
    },
    filterData: {
      type: Object,
      default: () => {},
    },
    pageData: {
      type: Object,
      default: () => {},
    },
    blockData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      catalogData: [],
      currentPage: 1,
      itemsOnPage: 20,
      totalPages: 1,
      loading: false,
      loadMoreLoading: false,
      catalogDataLoaded: false,
      observer: null,
      scrolledOnce: false,
      isMounted: false,
      scrollPosition: 0,
      SCROLL_POSITION_TO_SHOW_ARROW: 1500,
      catalogBottomPosition: 0,
      windowHeight: 0,
      totalItems: 0,
    };
  },
  async fetch() {
    if (this.$route?.query?.page) {
      this.currentPage = Number(this.$route?.query?.page);
    }
    this.loading = true;
    await this.getCatalog(false, true);
    this.loading = false;
  },
  head() {
    const jsonLdPayload = this.setPLPPageLdSchema();
    return {
      script: [
        {
          hid: this.getJsonLdScriptHid(jsonLdPayload),
          type: "application/ld+json",
          json: jsonLdPayload,
        },
      ],
    };
  },
  computed: {
    statisticItemListName() {
      const listName =
        this.pageData?.mixedTranslation?.title ||
        this.currentCollection?.mixedTranslation?.title ||
        this.$route.query.search;
      return listName || this.$t("catalog");
    },
    canSendGoogleAnalyticsStatistic() {
      return (
        this.integrationCallbackStatuses.TYPE_GOOGLE_ANALYTICS &&
        this.catalogDataLoaded
      );
    },
    canScrollToPage() {
      return (
        this.isMounted &&
        !this.loading &&
        !this.scrolledOnce &&
        this.catalogData.length
      );
    },
  },
  watch: {
    canSendGoogleAnalyticsStatistic: {
      handler() {
        this.gaViewItemListEvent(this.catalogData, {
          item_list_name: this.statisticItemListName,
        });
        this.setGaViewCatalogEvent();
      },
      immediate: true,
    },
    canScrollToPage: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.scrollToLastPage();
          }, 300);
        }
      },
      immediate: true,
    },
    "$route.query": {
      async handler() {
        if (
          Number(this?.$route?.query?.page || 1) === 1 ||
          Number(this?.$route?.query?.page || 1) !== this.currentPage
        ) {
          this.currentPage =
            Number(this?.$route?.query?.page) || this.currentPage;
          this.loading = true;
          await this.getCatalog();
          this.loading = false;
          this.getCatalogBottomPosition();
        }
      },
    },
  },
  mounted() {
    this.isMounted = true;
    window.addEventListener("scroll", this.updateScroll);
    this.createObserver();
    this.getCatalogBottomPosition();
  },
  beforeDestroy() {
    if (this.observer && this.$refs.loadMoreRef) {
      this.observer.disconnect();
    }
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    async clickCallback(page) {
      const isJustScroll = this.scrollToLastPage(page, false);
      if (isJustScroll) {
        return;
      }
      await this.updatePage(page, true);
    },
    async updatePage(page, scrollTop) {
      this.currentPage = page;
      await this.$router.replace({ query: { ...this.$route.query, page } });
      if (scrollTop) {
        this.$nextTick(() => {
          const element = document.querySelector(".collection__top-filter");

          if (element) {
            element.scrollIntoView();
          } else {
            window.scrollTo(0, 0);
          }
        });
      }
    },
    setGaViewCatalogEvent() {
      if (!this.catalogData) return;
      if (this.$route.query.search) {
        this.gaDynamicParamsEvent(`View Search`, {
          ecomm_prodid: this.catalogData.map((item) => item.default_sku),
          ecomm_pagetype: "searchresults",
          ecomm_totalvalue: "",
          ecomm_category: "",
        });
      } else {
        this.gaDynamicParamsEvent(`View Category`, {
          ecomm_prodid: this.catalogData.map((item) => item.default_sku),
          ecomm_pagetype: "category",
          ecomm_totalvalue: "",
          ecomm_category: this.statisticItemListName,
        });
      }
    },
    async getCatalog(merge, getAll) {
      try {
        let params = {
          page: this.currentPage,
          count: this.itemsOnPage,
          // search: this.$route.query.search, // TODO search

          categories_sku: Array.isArray(this.$route.query?.categories)
            ? this.$route.query?.categories
            : this.$route.query?.categories
            ? [this.$route.query?.categories]
            : undefined,
          materials_sku: Array.isArray(this.$route.query?.materials)
            ? this.$route.query?.materials
            : this.$route.query?.materials
            ? [this.$route.query?.materials]
            : undefined,
          min_cost_from: this.$route.query?.min_cost_from || undefined,
          max_cost_to: this.$route.query?.max_cost_to || undefined,
        };
        const categoriesLength = this.blockData?.categories?.length;
        const collectionsLength = this.blockData?.collections?.length;
        const materialsLength = this.blockData?.materials?.length;
        if (categoriesLength) {
          params.categories = this.blockData?.categories.map((m) => m.id);
        }
        if (collectionsLength > 1) {
          params.collections = this.blockData?.collections.map((m) => m.id);
        }
        if (materialsLength) {
          params.materials_sku = [
            ...params.materials_sku,
            ...this.blockData?.materials.map((m) => m.sku),
          ];
        }

        const slug =
          !this.$route.query.search && // TODO search (collectionsLength === 1 || this.currentCollection) &&
          !categoriesLength &&
          !materialsLength
            ? this.$route?.params?.slug ||
              this.currentCollection?.slug ||
              (this.blockData?.collections &&
              this.blockData?.collections?.length === 1
                ? this.blockData?.collections[0].slug
                : "")
            : "";
        if (this.$route.query?.sortBy) {
          const [column, order] = this.$route.query.sortBy.split("|");
          params.column = column || this.$route.query.sortBy;
          params.order_by = order || "desc";
        } else {
          params.column = slug ? "collection_sort_order" : undefined;
          params.order_by = "asc";
        }

        if (this.$route.query.search) {
          // TODO search
          params = {
            page: this.currentPage,
            count: this.itemsOnPage,
            search: this.$route.query.search,
          };
          if (this.$route.query?.sortBy) {
            const [column, order] = this.$route.query.sortBy.split("|");
            params.column = column || this.$route.query.sortBy;
            params.order_by = order || "desc";
          } else {
            params.column = undefined;
            params.order_by = "asc";
          }
        }

        if (getAll) {
          params.count = (params.page || 1) * this.itemsOnPage;
          params.page = 1;
        }

        const { data } = await this.$api.products.getCatalog(params, slug);
        if (merge) {
          this.catalogData = [...this.catalogData, ...data.data];
        } else {
          this.catalogData = data.data;
        }
        this.totalPages = getAll
          ? Math.ceil(data.meta.total / this.itemsOnPage)
          : data.meta.last_page;
        this.totalItems = data.meta.total;
        this.catalogDataLoaded = true;

        this.$store.commit("general/SET_JSON_SCHEMA_DATA", {
          pageName:
            this.pageData.mixedTranslation?.title ||
            this.currentCollection.mixedTranslation?.title,
          pageImage:
            getImageByPosition(this.pageData.medias, "open-graph")?.file?.url ||
            getImageByPosition(this.pageData.medias, "page")?.file?.url ||
            getImageByPosition(this.currentCollection.medias, "open-graph")
              ?.file?.url ||
            getImageByPosition(this.currentCollection.medias, "list")?.file
              ?.url ||
            "",
          products: this.catalogData,
          numberOfItems: this.totalItems,
        });

        this.setGaViewCatalogEvent();

        // return data;
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
        if (err?.response?.status === 400) {
          this.$nuxt.error({ statusCode: 404 });
        }
      }
    },
    createObserver() {
      if (!this.$refs.loadMoreRef) {
        return;
      }

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      this.observer = new IntersectionObserver(this.loadMoreCallback, options);
      this.observer.observe(this.$refs.loadMoreRef);
    },
    loadMoreCallback(entries) {
      entries.forEach(async (entry) => {
        if (
          entry.isIntersecting &&
          this.totalPages > this.currentPage &&
          !this.loading
        ) {
          this.loadMoreLoading = true;
          this.observer.unobserve(entry.target);
          this.currentPage++;
          await this.getCatalog(true);
          await this.updatePage(this.currentPage, false);
          this.observer.observe(entry.target);
          this.loadMoreLoading = false;
          this.getCatalogBottomPosition();
        }
      });
    },
    scrollToLastPage(page = this.$route.query.page, last = true) {
      this.scrolledOnce = true;
      if (
        page && Number(page || 1) !== last ? 1 : 0 && this.catalogData.length
      ) {
        const firstItemFromSelectedPage = this.itemsOnPage * (Number(page) - 1);

        const productCardElements = document.querySelectorAll(
          ".product-card-list-item"
        );

        if (productCardElements[firstItemFromSelectedPage]) {
          productCardElements[firstItemFromSelectedPage].scrollIntoView();
        }
        return true;
      }
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.windowHeight = window.innerHeight;
      this.getCatalogBottomPosition();
    },
    getCatalogBottomPosition() {
      if (!this.isMounted) {
        return;
      }
      setTimeout(() => {
        // this.catalogBottomPosition =
        //   this.$refs?.["products-row"]?.getBoundingClientRect()?.bottom;
        this.catalogBottomPosition = this.$refs?.["products-row"]?.offsetTop;
      }, 0);
    },
  },
};
