export const scaledCoef = 0.0185;

// Defaults
const defaultOptions = {
  format: "image/png",
  quality: 0.92,
  width: 1200,
  height: 1200,
  crossOrigin: "Anonymous",
  large: false,
};

// Return Promise
export const mergeImages = (sources = [], options = {}) =>
  new Promise((resolve) => {
    options = Object.assign({}, defaultOptions, options);

    const canvas = document.createElement("canvas");

    // Load sources
    const images = sources.map(
      (source) =>
        new Promise((resolve, reject) => {
          // Convert sources to objects
          if (source.constructor.name !== "Object") {
            source = {
              src: source.src,
              cancasStyle: source.canvasStyle,
              scaledAssets: source.scaledAssets,
              verticalImages: source.verticalImages,
            };
          }

          // Resolve source and img when loaded
          const img = new Image();
          img.crossOrigin = options.crossOrigin;
          img.onerror = () => reject(new Error("Couldn't load image"));
          img.onload = () => resolve(Object.assign({}, source, { img }));
          img.src = source.src;
        })
    );

    // Get canvas context
    const ctx = canvas.getContext("2d");

    // When sources have loaded
    resolve(
      Promise.all(images).then((images) => {
        // Set canvas dimensions
        const getSize = (dim) =>
          options[dim] || Math.max(...images.map((image) => image.img[dim]));
        canvas.width = getSize("width");
        canvas.height = getSize("height");

        // Draw images to canvas
        let scaledAssetsCount = 0;
        images.forEach((image, idx) => {
          ctx.globalAlpha = image.opacity ? image.opacity : 1;

          const canvasStyle = image.canvasStyle
            ? JSON.parse(image.canvasStyle)
            : null;
          const scaledAssets = image.scaledAssets
            ? JSON.parse(image.scaledAssets)
            : false;

          if (canvasStyle) {
            rotateImage({
              ctx,
              tX: canvasStyle.translate.x,
              tY: canvasStyle.translate.y,
              angle: canvasStyle.rotate,
            });
          }

          if (scaledAssets) {
            if (
              scaledAssets.isCustomFirstInitial &&
              images.filter((el) => el.scaledAssets).length === 1
            ) {
              ctx.drawImage(
                image.img,
                image.x || 0,
                image.y || 0,
                canvas.width,
                canvas.height
              );
              ctx.restore();
            } else {
              const { coordX, coordY, imageWidth, imageHeight } =
                getScaledAssetsParams(
                  images,
                  canvas,
                  image,
                  scaledAssetsCount,
                  scaledAssets,
                  options.large,
                  image.verticalImages
                );
              ctx.drawImage(image.img, coordX, coordY, imageWidth, imageHeight);
              ctx.restore();
              scaledAssetsCount++;
            }
          } else {
            ctx.drawImage(
              image.img,
              image.x || 0,
              image.y || 0,
              canvas.width,
              canvas.height
            );
            ctx.restore();
          }
        });
        // Resolve all other data URIs sync
        return canvas.toDataURL(options.format, options.quality);
      })
    );
  });

function rotateImage(data) {
  const { ctx, tX, tY, angle } = data;
  ctx.save();
  ctx.translate(tX, tY);
  ctx.rotate((angle * Math.PI) / 180);
}

function getScaledAssetsParams(
  images,
  canvas,
  image,
  scaledAssetsCount,
  scaledAssets,
  large,
  verticalImages
) {
  const largeDel = large ? 2 : 1;

  const maxImageWidth =
    Math.max(...images.map((img) => img.img.naturalWidth)) / largeDel;
  // if (maxImageWidth < 650) {
  //   maxImageWidth = 650;
  // }

  const imagesSizes = images.reduce(
    (acc, el) => {
      if (el.scaledAssets) {
        const elWidth = el.img.naturalWidth / largeDel;
        const elHeight = el.img.naturalHeight / largeDel;
        const baseWidth =
          elWidth * scaledAssets.minWidthCoef * (canvas.width / maxImageWidth);
        const baseHeight =
          elHeight * scaledAssets.minWidthCoef * (canvas.width / maxImageWidth);

        let imageWidth, imageHeight;

        if (verticalImages) {
          imageHeight =
            elHeight *
            (100 / images.filter((el) => el.scaledAssets).length) *
            scaledCoef *
            (canvas.height / maxImageWidth);

          imageWidth =
            (imageHeight * el.img.naturalWidth) / el.img.naturalHeight;
        } else {
          imageWidth =
            elWidth *
            (100 / images.filter((el) => el.scaledAssets).length) *
            scaledCoef *
            (canvas.width / maxImageWidth);

          imageHeight =
            (imageWidth * el.img.naturalHeight) / el.img.naturalWidth;
        }

        acc.baseSum = acc.baseSum + baseWidth;
        acc.base.push(baseWidth);
        acc.baseSumHeight = acc.baseSumHeight + baseHeight;
        acc.baseHeight.push(baseHeight);
        acc.newSum = acc.newSum + imageWidth;
        acc.new.push(imageWidth);
        acc.newSumHeight = acc.newSumHeight + imageHeight;
        acc.newHeight.push(imageHeight);
      }
      return acc;
    },
    {
      base: [],
      baseSum: 0,
      baseSumHeight: 0,
      baseHeight: [],
      new: [],
      newSum: 0,
      newSumHeight: 0,
      newHeight: [],
    }
  );

  const imgWidth = image.img.naturalWidth / largeDel;
  const imgHeight = image.img.naturalHeight / largeDel;

  const baseWidth =
    imgWidth * scaledAssets.minWidthCoef * (canvas.width / maxImageWidth);

  const newImageWidth =
    imgWidth *
    (100 / images.filter((el) => el.scaledAssets).length) *
    scaledCoef *
    (canvas.width / maxImageWidth);

  let fullInitialHeight, heightArr, imageWidth, fullInitialWidth, widthArr;

  if (verticalImages) {
    if (imagesSizes.baseSumHeight > canvas.height * 0.7) {
      imageWidth = newImageWidth;
      fullInitialWidth = imagesSizes.newSum;
      widthArr = imagesSizes.new;

      fullInitialHeight = imagesSizes.newSumHeight;
      heightArr = imagesSizes.newHeight;
    } else {
      imageWidth = baseWidth;
      fullInitialWidth = imagesSizes.baseSum;
      widthArr = imagesSizes.base;

      fullInitialHeight = imagesSizes.baseSumHeight;
      heightArr = imagesSizes.baseHeight;
    }
  } else if (imagesSizes.baseSum > canvas.width * 0.7) {
    imageWidth = newImageWidth;
    fullInitialWidth = imagesSizes.newSum;
    widthArr = imagesSizes.new;

    fullInitialHeight = imagesSizes.newSumHeight;
    heightArr = imagesSizes.newHeight;
  } else {
    imageWidth = baseWidth;
    fullInitialWidth = imagesSizes.baseSum;
    widthArr = imagesSizes.base;

    fullInitialHeight = imagesSizes.baseSumHeight;
    heightArr = imagesSizes.baseHeight;
  }

  const imageHeight = (imageWidth * imgHeight) / imgWidth;
  let coordX;
  let coordY;
  if (verticalImages) {
    coordX = (canvas.width - imageWidth) / 2;
    coordY =
      (canvas.height - fullInitialHeight) / 2 +
      heightArr.reduce((acc, el, idx) => {
        if (idx < scaledAssetsCount) {
          acc = acc + el;
        }
        return acc;
      }, 0);
  } else {
    coordX =
      (canvas.width - fullInitialWidth) / 2 +
      widthArr.reduce((acc, el, idx) => {
        if (idx < scaledAssetsCount) {
          acc = acc + el;
        }
        return acc;
      }, 0);

    coordY = canvas.height / 2 - imageHeight / 2;
  }

  if (scaledAssets.spaceLeftPercent && scaledAssetsCount > 0) {
    coordX =
      coordX +
      ((canvas.width * scaledAssets.spaceLeftPercent) / 100) *
        scaledAssetsCount;
  }

  if (scaledAssets.letterTopPercent) {
    const letterTopPercent = scaledAssets.letterTopPercent;
    const initial = JSON.parse(image.initial);

    let letterTopPercentCoef = letterTopPercent[initial];
    if (newImageWidth < baseWidth) {
      const oldImgHeight = (baseWidth * imgHeight) / imgWidth;
      const newHeight = (newImageWidth * imgHeight) / imgWidth;

      letterTopPercentCoef =
        (newHeight / oldImgHeight) * letterTopPercent[initial];
    }
    const coordYIncrement = ((canvas.height / 2) * letterTopPercentCoef) / 100;
    coordY = coordY + coordYIncrement;
  }
  return {
    coordX,
    coordY,
    imageWidth,
    imageHeight,
  };
}
