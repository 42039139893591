import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      integrationCallbackStatuses: "general/getIntegrationCallbackStatuses",
      getCollectionList: "general/getCollectionList",
      getCartItems: "cart/getCartItems",
      getCompanyName: "general/getCompanyName",
    }),
    storeSettings() {
      return this.$store.state.general.settings;
    },
    isGoogleAnalyticsAvailable() {
      return (
        this.integrationCallbackStatuses.TYPE_GOOGLE_ANALYTICS &&
        window?.dataLayer
      );
    },
  },
  methods: {
    getProductCollection(id) {
      if (!id) {
        return "";
      }
      const collection = this.getCollectionList?.find((item) => item.id === id);
      return collection?.mixedTranslation?.title || id;
    },
    googleAnalyticsPriceFormat(price) {
      price = price || 0;
      return Number(price).toFixed(2);
    },
    gaPurchaseEvent(data) {
      if (!this.isGoogleAnalyticsAvailable || !data) {
        return;
      }
      window.dataLayer.push({ ecommerce: null });
      const discountCodes = data.options.discounts
        ? Object.keys(data.options.discounts)
        : [];
      const giftCardCodes = data.options.gift_cards
        ? Object.keys(data.options.gift_cards)
        : [];
      const codes = [...discountCodes, ...giftCardCodes];
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: data.po_number, // ідентифікатор транзакції
          affiliation: "Online Store", // де здійснюється покупка
          value: this.googleAnalyticsPriceFormat(data.amount), // сума транзакції
          tax: this.googleAnalyticsPriceFormat(data.tax_amount), // налоги, якщо вони є
          shipping: this.googleAnalyticsPriceFormat(data.shipping_amount), // ціна доставки, якщо вона є
          currency: data.currency_code, // валюта, якщо гривні, то UAH, якщо доллар, то USD
          coupon: codes?.length ? codes.join(", ") : "", // купон, якщо є
          items: data.order_products.map((item) => ({
            item_name: item.mixedTranslation.title, // назва товару
            item_id: item.id, // ідентифікатор товару
            price: this.googleAnalyticsPriceFormat(item.cost), // ціна товару
            item_brand: this.getCompanyName, // бренд товару

            item_category: "", // категорія товару

            item_variant: item.configuration_id, // варіант товару
            quantity: item.quantity, // кількість замовленого товару
          })),
        },
      });
    },
    gaViewItemListEvent(data, extraData) {
      if (!this.isGoogleAnalyticsAvailable || !data?.length) {
        return;
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: data.map((item, index) => ({
            item_name: item?.mixedTranslation?.title || item.title, // потрібно вказати ім’я або ідентифікатор
            item_id: item.id, // ідентифікатор товару
            price: this.googleAnalyticsPriceFormat(item.base_cost), // ціна товару
            item_brand: this.getCompanyName, // бренд товару
            item_category: this.getProductCollection(item.collections_ids?.[0]), // категорія товару
            item_category2: "", // другий рівень категорії товару
            item_category3: "", // третій рівень категорії товару
            item_category4: "", // четвертий рівень категорії товару
            item_variant: item.default_sku, // варіант товару
            item_list_name: extraData?.item_list_name || "", // назва списку товарів
            item_list_id: item.default_sku, // унікальний ідентифікатор переліку товарів
            index: index + 1, // позиція товару у переліку товарів
            quantity: 1, // кількість замовленого товару
          })),
        },
      });
    },
    gaSelectItemEvent(data, extraData) {
      if (!this.isGoogleAnalyticsAvailable || !data) {
        return;
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_name: data?.mixedTranslation?.title || data.title, // потрібно вказати ім’я або ідентифікатор
              item_id: data.id, // ідентифікатор товару
              price: this.googleAnalyticsPriceFormat(data.base_cost), // ціна товару
              item_brand: this.getCompanyName, // бренд товару
              item_category: this.getProductCollection(
                data.collections_ids?.[0]
              ), // категорія товару
              item_category2: "", // другий рівень категорії товару
              item_category3: "", // третій рівень категорії товару
              item_category4: "", // четвертий рівень категорії товару
              item_variant: data.default_sku, // варіант товару
              item_list_name: extraData?.item_list_name || "", // назва списку товарів
              item_list_id: data.default_sku, // унікальний ідентифікатор переліку товарів
              index: extraData.index + 1, // позиція товару у переліку товарів
              quantity: 1, // кількість замовленого товару
            },
          ],
        },
      });
    },
    gaViewItemEvent(data, configurationData) {
      if (!this.isGoogleAnalyticsAvailable || !data) {
        return;
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_name: data?.mixedTranslation?.title || data?.title, // назва товару
              item_id: data.id, // ідентифікатор товару
              price: this.googleAnalyticsPriceFormat(
                configurationData?.cost || data.base_cost
              ), // ціна товару
              item_brand: this.getCompanyName, // бренд товару
              item_category:
                data.collections?.[0]?.mixedTranslation?.title || "", // категорія товару
              item_category2: "", // другий рівень категорії товару
              item_category3: "", // третій рівень категорії товару
              item_category4: "", // четвертий рівень категорії товару
              item_variant: configurationData?.configuration_id || "", // варіант товару

              item_list_name: "", // назва списку товарів

              item_list_id: configurationData?.configuration_id || "", // унікальний ідентифікатор переліку товарів
              index: 1, // позиція товару у переліку товарів
              quantity: 1, // кількість замовленого товару
            },
          ],
        },
      });
    },
    gaAddToCartEvent(data, configurationData) {
      if (!this.isGoogleAnalyticsAvailable || !data) {
        return;
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_name: data?.mixedTranslation?.title || data?.title, // назва товару
              item_id: data.id, // ідентифікатор товару
              price: this.googleAnalyticsPriceFormat(
                configurationData?.cost || data.base_cost
              ), // ціна товару
              item_brand: this.getCompanyName, // бренд товару
              item_category:
                data.collections?.[0]?.mixedTranslation.title || "", // категорія товару
              item_category2: "", // другий рівень категорії товару
              item_category3: "", // третій рівень категорії товару
              item_category4: "", // четвертий рівень категорії товару
              item_variant: configurationData?.configuration_id || "", // варіант товару

              item_list_name: "", // назва списку товарів

              item_list_id: configurationData?.configuration_id || "", // унікальний ідентифікатор переліку товарів
              index: this.getCartItems?.length
                ? this.getCartItems.length + 1
                : 1, // позиція товару у переліку товарів
              quantity: 1, // кількість замовленого товару
            },
          ],
        },
      });
    },
    gaRemoveFromCartEvent(data, index) {
      if (!this.isGoogleAnalyticsAvailable || !data) {
        return;
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          items: [
            {
              item_name: data?.mixedTranslation?.title || data?.title, // назва товару
              item_id: data.product_id, // ідентифікатор товару
              price: this.googleAnalyticsPriceFormat(data.base_cost), // ціна товару
              item_brand: this.getCompanyName, // бренд товару

              item_category: "", // категорія товару

              item_variant: data.configuration_id, // варіант товару

              item_list_name: "", // назва списку товарів

              item_list_id: data.configuration_id, // унікальний ідентифікатор переліку товарів
              index: index + 1, // позиція товару у переліку товарів
              quantity: data.quantity, // кількість замовленого товару
            },
          ],
        },
      });
    },
    gaBeginCheckoutEvent(data) {
      if (!this.isGoogleAnalyticsAvailable || !data?.length) {
        return;
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          items: data.map((item, index) => ({
            item_name: data?.mixedTranslation?.title || data?.title, // назва товару
            item_id: item.id, // ідентифікатор товару
            price: this.googleAnalyticsPriceFormat(item.base_cost), // ціна товару
            item_brand: this.getCompanyName, // бренд товару

            item_category: "", // категорія товару

            item_category2: "", // другий рівень категорії товару
            item_category3: "", // третій рівень категорії товару
            item_category4: "", // четвертий рівень категорії товару
            item_variant: item.configuration_id, // варіант товару

            item_list_name: "", // назва списку товарів

            item_list_id: item.configuration_id, // унікальний ідентифікатор переліку товарі
            index: index + 1, // позиція товару у переліку товарів
            quantity: item.quantity, // кількість замовленого товару
          })),
        },
      });
    },
    gaAddShippingInfoEvent(data, shippingMethodData) {
      if (!this.isGoogleAnalyticsAvailable || !data) {
        return;
      }
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
          shipping_tier: shippingMethodData.deliveryServiceName, // Назва служби доставки
          value: this.googleAnalyticsPriceFormat(shippingMethodData.price), // Ціна доставки
          currency: this.storeSettings?.currency_code || "", // Валюта доставки
          items: data.items.map((item, index) => {
            const discountCodes = item.options.discounts
              ? Object.keys(item.options.discounts)
              : [];
            const giftCardCodes = item.options.gift_cards
              ? Object.keys(item.options.gift_cards)
              : [];
            const codes = [...discountCodes, ...giftCardCodes];
            return {
              item_name: item.title, // Назва товару
              item_id: item.id, // id товару
              price: this.googleAnalyticsPriceFormat(item.base_cost), // Ціна товару
              item_brand: this.getCompanyName, // Бренд товару

              item_category: "", // Категорія товару

              item_category2: "", // Категорія товару	2
              item_category3: "", // Категорія товару	3
              item_category4: "", // Категорія товару	4
              item_variant: item.configuration_id, // Модифікація

              item_list_name: "", // Група товару

              item_list_id: item.configuration_id, // id групи товару
              index: index + 1, // Порядковий номер товару в категоріі
              quantity: item.quantity, // Кількість товару
              discount: item?.discount_amount || "", // Знижка
              coupon: codes?.length ? codes.join(", ") : "", // Купон
            };
          }),
        },
      });
    },
    gaDynamicParamsEvent(event, data) {
      if (!this.isGoogleAnalyticsAvailable || !data) {
        return;
      }

      const { ecomm_prodid, ecomm_pagetype, ecomm_totalvalue, ecomm_category } =
        data;
      window.dataLayer.push({
        event: event || "Dynamic Remarketing",
        dynamicParams: {
          ecomm_prodid: ecomm_prodid || "",
          ecomm_pagetype: ecomm_pagetype || "",
          ecomm_totalvalue: ecomm_totalvalue || "",
          ecomm_category: ecomm_category || "",
        },
      });
    },
  },
};
