
export default {
  props: {
    svgWidth: {
      type: Number,
      default: 64,
    },
    svgHeight: {
      type: Number,
      default: 64,
    },
  },
};
