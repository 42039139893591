import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getZendeskChatIntegration: "general/getZendeskChatIntegration",
      getKlaviyoIntegration: "general/getKlaviyoIntegration",
    }),
    canSendZendeskKlaviyoStatistic() {
      return this.getZendeskChatIntegration?.events_enabled || this.getKlaviyoIntegration?.events_enabled;
    },
  },
  methods: {
    async useZendeskAndKlaviyoEvents(payload) {
      if (!this.canSendZendeskKlaviyoStatistic) {
        return;
      }
      try {
        await this.$api.events.sendEventToBack(payload);
      } catch (err) {
        console.error("Error sending ZendeskAndKlaviyoEvents:", err);
      }
    },
  },
};
