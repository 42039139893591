import Vue from "vue";
import useEvents from "~/mixins/useEvents";

export default function ({ app, ...args }) {
  if (process.client) {
    const EventsComponent = Vue.extend({
      mixins: [useEvents],
      computed: {
        currentApp() {
          return app;
        },
      },
      ...args,
    });
    const eventsInstance = new EventsComponent();
    eventsInstance.visitEvent();
  }
}
