import { discountConfig } from "~/config";
import { priceFormat } from "@/utils";

export const state = () => ({
  cartInfo: null,
});

export const getters = {
  getCartItems(state) {
    return state.cartInfo?.items || [];
  },
  getCartInfo(state) {
    return state.cartInfo;
  },
  getCartItemsCount(state, getters) {
    const items = getters.getCartItems;
    return items.reduce((acc, item) => {
      return acc + Number(item.quantity) || 0;
    }, 0);
  },

  // getGiftCard(state) {
  //   return state.cartInfo?.gift_card || null;
  // },

  // getDiscountCard(state) {
  //   const discountCard = state.cartInfo?.sale || state.cartInfo?.discount;
  //   return discountCard
  //     ? {
  //         ...discountCard,
  //         is_automatic: !!state.cartInfo.sale,
  //       }
  //     : null;
  // },

  // getDiscountSource(state, getters) {
  //   return getters.getGiftCard || getters.getDiscountCard;
  // },

  // isProductsDiscount(state, getters) {
  //   return (
  //     (getters.getDiscountCard &&
  //       getters.getCartItems.some((item) => item.discount)) ||
  //     getters.getCartItems.some((item) =>
  //       item.sub_items?.some((subItem) => subItem.discount)
  //     )
  //   );
  // },

  // getDiscountProductsSubtotal(state, getters) {
  //   return getters.getCartItems.reduce((acc, item) => {
  //     if (item.discount) {
  //       acc += Number(item.base_amount) * (item.quantity || 1);
  //     }
  //     if (item.sub_items?.some((subItem) => subItem.discount)) {
  //       acc += item.sub_items.reduce((subAcc, subItem) => {
  //         if (subItem.discount) {
  //           subAcc += Number(subItem.base_amount) * (item.quantity || 1);
  //         }
  //         return subAcc;
  //       });
  //     }
  //     return acc;
  //   }, 0);
  // },

  // getFixedProductsTotalDiscountSum(state, getters) {
  //   return getters.getCartItems.reduce((acc, item) => {
  //     if (item.discount) {
  //       const discountAmount =
  //         Number(item.discount.amount) < Number(item.base_amount)
  //           ? Number(item.discount.amount)
  //           : Number(item.base_amount);
  //       acc += discountAmount * (item.quantity || 1);
  //     }
  //     if (item.sub_items?.some((subItem) => subItem.discount)) {
  //       acc += item.sub_items.reduce((subAcc, subItem) => {
  //         if (subItem.discount) {
  //           const discountAmount =
  //             Number(subItem.discount.amount) < Number(subItem.base_amount)
  //               ? Number(subItem.discount.amount)
  //               : Number(subItem.base_amount);
  //           subAcc += discountAmount * (item.quantity || 1);
  //         }
  //         return subAcc;
  //       });
  //     }
  //     return acc;
  //   }, 0);
  // },
  getDiscountItems(state, getters) {
    return (data) => {
      if (data?.options?.discounts) {
        return Object.values(data.options.discounts);
      }
      return [];
    };
  },
  getGiftCardItems(state, getters) {
    return (data) => {
      if (data?.options?.gift_cards) {
        return Object.values(data.options.gift_cards);
      }
      return [];
    };
  },
  getDiscountValue(state, getters, rootState) {
    return (item) => {
      switch (item.discount_type) {
        case discountConfig.type.static:
          return `-${priceFormat(
            item.used_discount_amount,
            rootState.general?.settings?.currency_sign
          )}`;
        case discountConfig.type.percent:
          return `-${priceFormat(
            item.used_discount_amount,
            rootState.general?.settings?.currency_sign
          )}(${item.base_discount_amount}%)`;
        default:
          return null;
      }
    };
  },
  getGiftCardValue(state, getters, rootState) {
    return (item) => {
      switch (item.gift_card_type) {
        case discountConfig.type.static:
          return `-${priceFormat(
            item.used_gift_card_amount,
            rootState.general?.settings?.currency_sign
          )}`;
        case discountConfig.type.percent:
          return `-${priceFormat(
            item.used_gift_card_amount,
            rootState.general?.settings?.currency_sign
          )}(${item.base_gift_card_amount}%)`;
        default:
          return null;
      }
    };
  },
};

export const mutations = {
  SET_CART_INFO(state, payload) {
    state.cartInfo = payload;
  },
  RESET_CART(state) {
    state.cartInfo = null;
  },
};

export const actions = {
  async updateCartItem({ commit, rootState }, item) {
    const params = {
      cart_unique_id: rootState.localStorage.cart_unique_id,
      ...item,
    };
    try {
      const { data } = await this.$api.cart.updateCartItem(item.id, params);
      commit("SET_CART_INFO", data.data);
    } catch (e) {
      console.log(e);
    }
  },
  async removeCartItem({ commit, getters, rootState, rootGetters }, id) {
    const params = {
      cart_unique_id: rootState.localStorage.cart_unique_id,
    };
    try {
      await this.$api.cart.deleteCartItem(id, params);

      const cartId = rootState.localStorage.cart_unique_id;
      const userInfo = rootGetters["auth/getUserInfo"];

      const { data } = await this.$api.cart.getCart({ cartId, userInfo });
      commit("SET_CART_INFO", data.data);
      // await dispatch("getCart");
      // const items = getters.getCartItems.filter((i) => i.id !== id);
      // commit("SET_CART_INFO", {
      //   ...this.cartInfo,
      //   items,
      // });
    } catch (e) {
      console.log(e);
    }
  },

  async bindDiscount({ commit, getters, rootState }, code) {
    const params = {
      cart_unique_id: rootState.localStorage.cart_unique_id,
      gift_card_code: code,
    };
    try {
      const { data } = await this.$api.cart.bindDiscount(params);
      commit("SET_CART_INFO", data.data);
      return Promise.resolve(data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async unbindDiscount({ commit, getters, rootState }, code) {
    const params = {
      cart_unique_id: rootState.localStorage.cart_unique_id,
      gift_card_code: code,
    };
    try {
      const { data } = await this.$api.cart.unbindDiscount(params);
      commit("SET_CART_INFO", data.data);
      return Promise.resolve(data.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};
