
import { mapGetters } from "vuex";
import { isEqual, debounce } from "lodash";
import UiCheckbox from "@/components/UI/UiCheckbox.vue";

import { getElementHeight, getImageByPosition } from "~/utils";
import UiRadio from "~/components/UI/UiRadio.vue";
import UiAccordion from "@/components/UI/UiAccordion.vue";
import UiAccordionItem from "@/components/UI/UiAccordionItem.vue";
import CloseSvg from "~/components/svg/CloseSvg.vue";

export default {
  name: "UiFilter",
  components: {
    CloseSvg,
    UiRadio,
    UiCheckbox,
    UiAccordion,
    UiAccordionItem,
  },
  props: {
    filterSchema: {
      type: [Object, null],
      default: null,
    },
    sortByOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentWidth: 600,
      isMounted: false,
      isFilterOpen: false,
      isFilterOpenMobile: false,
      tempResult: {},
      results: {},
      showAllObj: {},
      sortBy: "",
      showAllCategory: false,
      maxHeight: { maxHeight: "0px" },
      maxFilterItems: 3,
      applyingFilters: false,
      applyFiltersDebounce: () => {},
      mobileFilterType: null,
      mobileMenuData: {
        filter: {
          title: this.$t("filter"),
        },
        sort: {
          title: this.$t("sorting"),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getMaterialsCategories: "general/getMaterialsCategories",
      getCollectionList: "general/getCollectionList",
    }),
    formatedMaterials() {
      const res = this.getMaterialsCategories.reduce((acc, categ) => {
        const data = this.filterSchema.materials.reduce((acc, el) => {
          if (el.material_category_id === categ.id) {
            acc.push({
              label: el.mixedTranslation?.title,
              value: el.sku,
              medias: el.medias,
            });
          }
          return acc;
        }, []);
        if (data.length) {
          acc.push({
            ...categ,
            data,
          });
        }
        return acc;
      }, []);
      return res;
    },
  },
  watch: {
    filterSchema: {
      handler(val) {
        this.tempResult = Object.keys(val).reduce((acc, el) => {
          acc[el] = [];
          return acc;
        }, {});

        this.results = Object.keys(val).reduce((acc, el) => {
          acc[el] = [];
          return acc;
        }, {});

        this.showAllObj = Object.keys(val).reduce((acc, el) => {
          acc[el] = false;
          return acc;
        }, {});
        this.getQueryData();
      },
      immediate: true,
    },
    isFilterOpen: {
      handler(val) {
        if (this.currentWidth <= 991 && this.isMounted) {
          this.maxHeight = { maxHeight: "0px" };
        }
        this.isFilterOpenMobile = false;
        if (val || this.isFilterOpenMobile) {
          this.maxHeight = this.calculateMaxHeight();
        } else {
          this.maxHeight = { maxHeight: "0px" };
        }
      },
    },
    isFilterOpenMobile: {
      handler(val) {
        if (this.currentWidth <= 991 && this.isMounted) {
          this.maxHeight = { maxHeight: "0px" };
          document.body.style.overflow = val ? "hidden" : "auto";
          const chatIcon = document.querySelector("[data-id='zsalesiq']");
          if (chatIcon) {
            chatIcon.style.display = val ? "none" : "block";
          }
        }
        if (val || this.isFilterOpen) {
          this.maxHeight = this.calculateMaxHeight();
        } else {
          this.maxHeight = { maxHeight: "0px" };
        }

        if (!val) {
          this.tempResult = JSON.parse(JSON.stringify(this.results));
        }
      },
    },
    "$route.query": {
      async handler(val) {
        if (!this.applyingFilters) {
          this.getQueryData();
        }
        await this.removeFiltersWhileSearch(val.search); // TODO search
      },
    },
  },
  mounted() {
    this.applyFiltersDebounce = debounce(() => {
      this.applyFilters(false);
    }, 700);
    this.currentWidth = window.innerWidth;
    this.isMounted = true;
    this.getCurrentWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setCurrentWidth);
  },
  methods: {
    getImageByPosition,
    resetFilters() {
      Object.keys(this.tempResult).forEach((el) => {
        this.tempResult[el] = [];
      });
      this.applyFilters(true);
    },
    collectionsLink(slug) {
      // if (
      //   this.getCollectionList.some(
      //     (el) => el.slug === this.$route.path.split("/")[1]
      //   )
      // ) {
      //   return `/catalog/${slug}`;
      // } else {
      //   return `/${this.$route.path.split("/")[1]}/${slug}`;
      // }
      return `/collection/${slug}`;
    },
    getQueryData() {
      Object.entries(this.$route.query).forEach(([queryKey, queryValue]) => {
        if (this.tempResult[queryKey] && this.results[queryKey]) {
          if (Array.isArray(queryValue)) {
            this.tempResult[queryKey] = queryValue.map((el) => el);
            this.results[queryKey] = queryValue.map((el) => el);
          } else {
            this.tempResult[queryKey] = [queryValue];
            this.results[queryKey] = [queryValue];
          }
        }
      });
      this.setSortBy();
    },
    async applyFilters(isScroll) {
      this.applyingFilters = true;
      const results = JSON.stringify(this.tempResult);
      if (JSON.stringify(this.results) !== results) {
        const newQueryParams = {
          ...this.$route.query,
          page: 1,
          ...JSON.parse(results),
          sortBy: this.sortBy,
        };

        delete newQueryParams.search; // TODO search

        await this.setQueryParams(newQueryParams);

        this.results = JSON.parse(results);
        this.applyingFilters = false;
      }
      this.isFilterOpen = false;
      this.isFilterOpenMobile = false;
      if (isScroll) {
        this.$nextTick(() => {
          const element = document.querySelector(".filter_top");

          if (element) {
            element.scrollIntoView();
          } else {
            window.scrollTo(0, 0);
          }
        });
      }
    },
    getElementHeight,
    async setQueryParams(data) {
      if (!isEqual(data, this.$route.query)) {
        await this.$router.replace(
          this.localePath({
            ...this.$route.query,
            query: data,
            path: this.$route.path,
          })
        );
        this.$emit("onSetFilters", data);
      }
    },
    removeChip(item, group) {
      this.applyingFilters = true;
      const results = Object.entries(this.results).reduce(
        (acc, [key, value]) => {
          if (key === group) {
            acc[key] = value.filter((el) => String(el) !== String(item));
          } else {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      this.setQueryParams({
        ...this.$route.query,
        page: 1,
        ...results,
        sortBy: this.sortBy,
      });
      this.results = results;
      this.tempResult = JSON.parse(JSON.stringify(results));
      this.applyingFilters = false;
    },
    getCurrentWidth() {
      window.addEventListener("resize", this.setCurrentWidth);
    },
    setCurrentWidth(e) {
      this.currentWidth = e.target.innerWidth;
    },
    onSort(val) {
      // console.log(e);
      this.setQueryParams({
        ...this.$route.query,
        sortBy: val || undefined,
        page: 1,
      });
    },
    setSortBy() {
      if (this.$route.query.sortBy) {
        this.sortBy = this.$route.query.sortBy;
      } else {
        this.setQueryParams({
          ...this.$route.query,
        });
      }
    },
    showFiltersReducer(data, showAll) {
      if (!showAll) {
        return data?.slice(0, this.maxFilterItems);
      }
      return data;
    },
    showAll() {
      console.log(111);
      this.$nextTick(() => {
        this.maxHeight = this.calculateMaxHeight();
      });
    },
    calculateMaxHeight() {
      return {
        maxHeight:
          this.isFilterOpen || this.isFilterOpenMobile
            ? this.getElementHeight(`.js-top-filter`) + 100 + "px"
            : "0px",
      };
    },
    onMobileFilterOpen(type) {
      this.mobileFilterType = type;
      this.isFilterOpenMobile = true;
    },
    removeFiltersWhileSearch(search) {
      // TODO search
      if (search) {
        Object.keys(this.tempResult).forEach((el) => {
          this.tempResult[el] = [];
        });

        const results = JSON.stringify(this.tempResult);
        if (JSON.stringify(this.results) !== results) {
          this.results = JSON.parse(results);
        }
      }
    },
    openInxByDefaultMaterials(idx) {
      const avaliableIdx = [0];
      if (
        !this.filterSchema.categories?.length ||
        this.filterSchema.categories?.length === 0
      ) {
        avaliableIdx.push(1);
      }
      return avaliableIdx.includes(idx) ? idx : null;
    },
  },
};
