

export default function(to, from, savedPosition) {
      if (to.hash) {
        return { selector: to.hash };
        // return window.scrollTo({
        //   top: document.querySelector(to.hash).offsetTop + window.innerHeight,
        //   behavior: "auto",
        // });
      } else if (to.path !== from.path) {
        // return { x: 0, y: 0 };
        //  return  window.scrollTo({ top: 0, behavior: "auto" });
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(window.scrollTo({ top: 0, behavior: "auto" }));
          }, 300);
        });
      }
      return savedPosition || "";
    }
