export default (axios) => {
  return {
    /**
     * Search
     *
     * @link https://devapi.omara.sk/apidoc/index.html#api-Search-GetSearchApiV1Search
     * @param data
     * @returns {*}
     */
    search(params) {
      return axios.get("/modules/search/search", { params });
    },

    /**
     * Suggestions Search by Completion
     *
     * @link https://devapi.omara.sk/apidoc/index.html#api-Search-GetSearchApiV1SuggestionsCompletion
     * @param data
     * @returns {*}
     */
    searchSuggestions(params) {
      return axios.get("/modules/search/suggestions", {
        params,
      });
    },
  };
};
