
// import { linkBuilder } from "@/utils";
import { setAttributeToElements } from "~/utils";

export default {
  name: "CardImageView",
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    to: {
      type: String,
      default: "",
      required: true,
    },
    imageBrightnessFilter: {
      type: [String, Number],
      default: 100,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    itemTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  mounted() {
    setAttributeToElements("a.hidden-ssr-link", "tabindex", "0");
  },
  // methods: {
  //   linkBuilder,
  // },
};
