/**
 * Format price
 * @param {number} number
 * @param {string} currencySign
 * @returns {string}
 */
export function priceFormat(number, currencySign = "USD") {
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "code",
  })
    .format(Number(number))
    .replace(/USD\s/, currencySign);
}
