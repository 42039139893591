import { isArray, isEmpty, isObject, isNumber, has } from "lodash";
export const generateId = (length = 10) => {
  let id = "";
  const possible = "0123456789";

  for (let i = 0; i < length; i++) {
    id += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return id;
};

export const getElementHeight = (selector) => {
  return document.querySelector(selector).scrollHeight;
};

export const shuffleArray = (array) => {
  const arrayCopy = [...array];
  let length = arrayCopy.length;
  let temp;
  let idx;

  // While there remain elements to shuffle…
  while (length) {
    // Pick a remaining element…
    idx = Math.floor(Math.random() * length--);

    // And swap it with the current element.
    temp = arrayCopy[length];
    arrayCopy[length] = arrayCopy[idx];
    arrayCopy[idx] = temp;
  }

  return arrayCopy;
};

export const topMenuFormatter = (data) => {
  const storeMenu = JSON.parse(JSON.stringify(data));
  storeMenu.sort((a, b) => a.parent_id - b.parent_id);

  let menu = storeMenu.filter((item) => !item.parent_id);

  menu = menu.map((el, idx) => {
    const children = storeMenu.filter((item) => item.parent_id === el.id);

    const colmns = [];
    children.forEach((child) => {
      if (child.is_title) {
        colmns.push({
          title: child,
          list: [],
        });
      } else {
        if (!colmns.length || !colmns.some((co) => !co.title)) {
          colmns.push({
            list: [],
          });
        }
        colmns.forEach((col) => {
          if (!col.title) {
            col.list.push(child);
          }
        });
      }
    });

    colmns.forEach((col) => {
      const secChildren = storeMenu.filter(
        (item) => col.title && col.title.id === item.parent_id
      );
      if (secChildren && secChildren.length) {
        col.list = secChildren;
      }
    });
    return {
      ...el,
      isOpen: false,
      columns: colmns,
    };
  });
  return menu;
};

export const linkBuilder = (link) => {
  if (!link) {
    return "";
  }
  return `${process.env.IMAGE_URL}${link}`;
};

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const productQueryBuilder = (item) => {
  let string = `?config=${item.sku}`;
  if (
    typeof item.engraving === "object" &&
    !Array.isArray(item.engraving) &&
    item.engraving !== null
  ) {
    Object.entries(item.engraving).forEach(([key, val], idx) => {
      string = string + `&${key}=${val}`;
    });
  }

  return string;
};

export function parseJson(code) {
  try {
    return JSON.parse(code);
  } catch (e) {
    return null;
  }
}

export function getImageByPosition(list, position) {
  return list?.find((el) => el.position === position);
}

export function objectToQuery(object) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (isArray(value) && !isEmpty(value)) {
      acc[`${key}[]`] = value;
    } else if (!isArray(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function mergeObjects(obj1, obj2) {
  const mergedObj = JSON.parse(JSON.stringify(obj1));
  for (const key in obj2) {
    if (has(obj2, key)) {
      if (isEmpty(obj1[key]) && !isNumber(obj1[key])) {
        mergedObj[key] = obj2[key];
      } else if (
        typeof mergedObj[key] === "object" &&
        typeof obj2[key] === "object"
      ) {
        mergedObj[key] = mergeObjects(mergedObj[key], obj2[key]);
      }
    }
  }
  return mergedObj;
}

export function addCurrentTranslation(
  data,
  translationId,
  defaultTranslationId
) {
  if (Array.isArray(data)) {
    return data.map((item) =>
      addCurrentTranslation(item, translationId, defaultTranslationId)
    );
  } else if (isObject(data)) {
    const newData = { ...data };

    if (Array.isArray(newData.translations)) {
      const currentTranslation = newData.translations.find(
        (translation) => translation.language_id === translationId
      );
      const defaultTranslation = newData.translations.find(
        (translation) => translation.language_id === defaultTranslationId
      );

      newData.currentTranslation = currentTranslation;

      newData.mixedTranslation = mergeObjects(
        currentTranslation || {},
        defaultTranslation || {}
      );
    }

    for (const key in newData) {
      if (has(newData, key)) {
        newData[key] = addCurrentTranslation(
          newData[key],
          translationId,
          defaultTranslationId
        );
      }
    }

    return newData;
  }
  return data;
}

/**
 * Checks if provided date is equal to today's date.
 *
 * @param {Date} date - The date to be compared with today's date.
 * @returns {boolean} Returns `true` if provided date is today's date, `false` otherwise.
 */
export function isToday(date) {
  const today = new Date();

  return today.toDateString() === date.toDateString();
}

/**
 * Get random item from array.
 *
 * @param {array} items - Array of items.
 * @returns {*} Returns random item from array
 */
export function getRandomArrayItem(items) {
  if (items?.length) {
    return items[Math.floor(Math.random() * items.length)];
  }
}

export function getRedirectUrl(
  path,
  availableLocales,
  queryParams = {},
  domain
) {
  const domainURL = new URL(domain);

  const url = new URL(path, domain);

  if (domainURL.pathname?.length > 1) {
    url.pathname = domainURL.pathname + url.pathname;
  }

  // Remove the locale path prefix if it exists
  const segments = url.pathname.split("/");
  if (availableLocales.includes(segments[1])) {
    segments.splice(1, 1);
    url.pathname = segments.join("/");
  }

  // Add or replace query parameters
  Object.keys(queryParams).forEach((key) =>
    url.searchParams.set(key, queryParams[key])
  );

  // Remove dummy origin and return the result
  return url.toString();
}

export function setAttributeToElements(identifier, attribute, attributeValue) {
  const linkEls = window.document.querySelectorAll(identifier);
  linkEls.forEach((el) => {
    el.setAttribute(attribute, attributeValue);
  });
}

/** Replace placeholder with value
 * @param {string} value
 * @param {function} callback
 * */
export function replacePlaceholder(value, callback) {
  return value.replace(/\{([^}]+)\}/g, (match, variable) => {
    return callback(variable) || match;
  });
}

export function removeHTMLTagFromString(str) {
  return str ? str.replace(/<[^>]*>/g, " ") : "";
}
