export const metaGenerator = (meta = {}) => {
  const metaTitle = meta.title || "";
  const metaDescription = meta.description || "";
  const metaImage = meta.image || "";
  const metaUrl = meta.url || "";
  const metaKeywords = meta.keywords || "";
  return [
    {
      hid: "description",
      name: "description",
      content: metaDescription,
    },
    {
      hid: "keywords",
      name: "keywords",
      content: metaKeywords,
    },
    {
      hid: "og:title",
      property: "og:title",
      content: metaTitle,
    },
    {
      hid: "og:type",
      property: "og:type",
      content: "website",
    },
    {
      hid: "og:description",
      property: "og:description",
      content: metaDescription,
    },
    {
      hid: "og:image",
      property: "og:image",
      content: metaImage,
    },
    {
      hid: "og:url",
      property: "og:url",
      content: metaUrl,
    },
    {
      hid: "og:site_name",
      property: "og:site_name",
      content: metaTitle,
    },

    {
      hid: "twitter:card",
      name: "twitter:card",
      content: "summary",
    },
    {
      hid: "twitter:site",
      name: "twitter:site",
      content: metaTitle,
    },
    {
      hid: "twitter:title",
      name: "twitter:title",
      content: metaTitle,
    },
    {
      hid: "twitter:description",
      name: "twitter:description",
      content: metaDescription,
    },
    {
      hid: "twitter:image",
      name: "twitter:image",
      content: metaImage,
    },

    {
      hid: "name",
      itemprop: "name",
      content: metaTitle,
    },
    {
      hid: "description",
      itemprop: "description",
      content: metaDescription,
    },
    {
      hid: "image",
      itemprop: "image",
      content: metaImage,
    },
  ];
};

export const metaGeneratorPDP = (meta = {}) => {
  const metaTitle = meta.title || "";
  const metaDescription = meta.description || "";
  const metaImage = meta.image || "";
  const metaUrl = meta.url || "";
  const metaKeywords = meta.keywords || "";

  const productBrand = meta.product_brand || "Omara";
  const productAvailability = meta.product_availability || "in stock";
  const productCondition = meta.product_condition || "new";
  const productPriceAmount = meta.product_price_amount || "";
  const productPriceCurrency = meta.product_price_currency || "";
  const productRetailer_item_id = meta.product_retailer_item_id || "";
  const productItem_group_id = meta.product_item_group_id || "";
  return [
    {
      hid: "description",
      name: "description",
      content: metaDescription,
    },
    {
      hid: "keywords",
      name: "keywords",
      content: metaKeywords,
    },
    {
      hid: "og:title",
      property: "og:title",
      content: metaTitle,
    },
    {
      hid: "og:type",
      property: "og:type",
      content: "website",
    },
    {
      hid: "og:description",
      property: "og:description",
      content: metaDescription,
    },
    {
      hid: "og:image",
      property: "og:image",
      content: metaImage,
    },
    {
      hid: "og:url",
      property: "og:url",
      content: metaUrl,
    },
    {
      hid: "og:site_name",
      property: "og:site_name",
      content: metaTitle,
    },

    {
      hid: "twitter:card",
      name: "twitter:card",
      content: "summary",
    },
    {
      hid: "twitter:site",
      name: "twitter:site",
      content: metaTitle,
    },
    {
      hid: "twitter:title",
      name: "twitter:title",
      content: metaTitle,
    },
    {
      hid: "twitter:description",
      name: "twitter:description",
      content: metaDescription,
    },
    {
      hid: "twitter:image",
      name: "twitter:image",
      content: metaImage,
    },

    {
      hid: "name",
      itemprop: "name",
      content: metaTitle,
    },
    {
      hid: "description",
      itemprop: "description",
      content: metaDescription,
    },
    {
      hid: "image",
      itemprop: "image",
      content: metaImage,
    },

    {
      hid: "product:brand",
      property: "product:brand",
      content: productBrand,
    },
    {
      hid: "product:availability",
      property: "product:availability",
      content: productAvailability,
    },
    {
      hid: "product:condition",
      property: "product:condition",
      content: productCondition,
    },
    {
      hid: "product:price:amount",
      property: "product:price:amount",
      content: productPriceAmount,
    },
    {
      hid: "product:price:currency",
      property: "product:price:currency",
      content: productPriceCurrency,
    },
    {
      hid: "product:retailer_item_id",
      property: "product:retailer_item_id",
      content: productRetailer_item_id,
    },
    {
      hid: "product:item_group_id",
      property: "product:item_group_id",
      content: productItem_group_id,
    },
  ];
};